import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
export default function UserCategories({ baseUrl }) {
    const [categoryData, setCategoryData] = useState([])
    async function GetCategoryList() {
        await axios.get(`${baseUrl}/api/Category/GetCategoryList`).then((res) => {
            setCategoryData(res.data)

        }).catch((err) => {
            
        })
    }

    useEffect(() => {
        GetCategoryList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (

        <section className="min-h-[90vh] flex flex-col w-full justify-center items-center gap-10" id='our-courses'>
            <h1 className="  font-bold md:text-5xl text-3xl text-center px-10">In which area do you want to develop?</h1>
            <div className="flex flex-wrap justify-around w-full sm:gap-5  ">
                {categoryData?.map((item, index) => {
                    return <Link to={`/Courses/${item?.id}`} className="w-40 lg:w-96 md:w-72 flex text-white no-underline flex-col justify-center items-center  sm:mb-0 hover:text-[--yellow] my-4">
                        <img src={`${baseUrl}/api/Storage/GetImageById?id=${item?.image}`} alt="" className="w-full sm:w-auto drop-shadow-lg transition-all duration-300 cursor-pointer hover:scale-95 scale-90 " />
                        <h1 className="mt-4 sm:mt-5 font-bold text-2xl sm:text-4xl text-center  transition-all duration-300">{item?.name}</h1>
                    </Link>
            })}
        </div>
        </section >
    )
}
