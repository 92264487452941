import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;

const EditCourseModal = ({ visible, onClose, baseUrl, fetchCourses, courseData }) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageId, setImageId] = useState(courseData?.cover || null);
    const [descriptionImageId, setDescriptionImageId] = useState(courseData?.descriptionImageId || null);
    const [secondaryImageId, setSecondaryImageId] = useState(courseData?.secondaryImageId || null);

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('providerToken')}`,
    };

    useEffect(() => {
        GetCategoryList();
        if (courseData) {
            form.setFieldsValue({
                name: courseData.name,
                descriptionText: courseData.descriptionText,
                categoryId: courseData.categoryId,
                price: courseData.price,
            });
            setImageId(courseData.cover);
            setDescriptionImageId(courseData.descriptionImageId);
            setSecondaryImageId(courseData.secondaryImageId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseData]);

    async function GetCategoryList() {
        try {
            const response = await axios.get(`${baseUrl}/api/Category/GetCategoryList`);
            setCategories(response.data);
        } catch (error) {
            message.error('Failed to load categories');
        }
    }

    const handleImageUpload = async ({ file, onProgress, onSuccess, onError }, setImageCallback) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${baseUrl}/api/Storage/UploadImage`, formData, {
                headers,
                onUploadProgress: (event) => {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    onProgress({ percent });
                },
            });

            setImageCallback(response.data.id);
            message.success('Image uploaded successfully');
            onSuccess(response.data);
        } catch (error) {
            message.error('Failed to upload image');
            onError(error);
        }
    };

    const handleEdit = () => {
        let values = form.getFieldsValue();
        if (!imageId || !descriptionImageId) {
            message.error('Please upload all required images');
            return;
        }

        setLoading(true);
        axios.put(`${baseUrl}/api/Course/EditCourse`, {
            ...values,
            cover: imageId,
            descriptionImageId,
            secondaryImageId,
            id: courseData.id,
            providerId: localStorage.getItem('providerID'),
        }, { headers })
            .then(response => {
                message.success('Course updated successfully');
                form.resetFields();
                fetchCourses();
                onClose();
            })
            .catch(error => {
                message.error('Failed to update course');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <Modal
            title="Edit Course"
            visible={visible}
            onCancel={onClose}
            closeIcon={false}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleEdit}>
                    Save Changes
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="editCourseForm"
                requiredMark={false}
                size='large'
            >
                <Form.Item
                    name="name"
                    label="Course Name"
                    rules={[{ required: true, message: 'Please enter the course name' }]}
                >
                    <Input placeholder="Enter course name" />
                </Form.Item>

                <Form.Item
                    name="cover"
                    label="Cover Image"
                    rules={[{ required: true, message: 'Please upload a cover image' }]}
                >
                    <Upload
                        name="cover"
                        listType="picture"
                        maxCount={1}
                        customRequest={(options) => handleImageUpload(options, setImageId)}
                        showUploadList={{ showRemoveIcon: false }}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="descriptionImageId"
                    label="Description Image"
                    rules={[{ required: true, message: 'Please upload a description image' }]}
                >
                    <Upload
                        name="descriptionImage"
                        listType="picture"
                        maxCount={1}
                        customRequest={(options) => handleImageUpload(options, setDescriptionImageId)}
                        showUploadList={{ showRemoveIcon: false }}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="secondaryImageId"
                    label="Secondary Image"
                    rules={[{ required: false }]}
                >
                    <Upload
                        name="secondaryImage"
                        listType="picture"
                        maxCount={1}
                        customRequest={(options) => handleImageUpload(options, setSecondaryImageId)}
                        showUploadList={{ showRemoveIcon: false }}
                    >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="descriptionText"
                    label="Description Text"
                    rules={[{ required: true, message: 'Please enter the course description' }]}
                >
                    <TextArea rows={4} placeholder="Enter course description" />
                </Form.Item>

                <Form.Item
                    name="categoryId"
                    label="Category"
                    rules={[{ required: true, message: 'Please select a category' }]}
                >
                    <Select placeholder="Select a category">
                        {categories.map(category => (
                            <Option key={category.id} value={category.id}>
                                {category.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="price"
                    label="Course Price"
                    rules={[{ required: true, message: 'Please enter the course price' }]}
                >
                    <Input placeholder="Enter course price" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditCourseModal;
