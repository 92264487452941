import React, { useEffect, useState } from "react";
import { Form, Input, message, Modal } from "antd";
import { FileTextOutlined, LockOutlined, UndoOutlined } from '@ant-design/icons';

import axios from "axios";
import "./payment.css";
import { useNavigate, useParams } from "react-router-dom";

export default function PaymentSteps({ baseUrl }) {
    const [currentStep, setCurrentStep] = useState(null);
    const [personalInfo, setPersonalInfo] = useState({});
    const [paymentInfo, setPaymentInfo] = useState({});
    const [isTermsModalVisible, setTermsModalVisible] = useState(false);
    const [isPrivacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [isRefundModalVisible, setRefundModalVisible] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [isRefundChecked, setIsRefundChecked] = useState(false);
    const [orderdata, setorderdata] = useState();
    let { id } = useParams();
    const [showDONE, setshowDONE] = useState();
    const token = localStorage.getItem('userToken');
    let navigate = useNavigate();

    const headers = {
        Authorization: `Bearer ${token}`,
    };
    const handleNext = (values) => {
        if (currentStep === 0) {
            setPersonalInfo(values);
            setCurrentStep(currentStep + 1);
        }
    };
    // Fetch default user data from API
    useEffect(() => {
        fetchUserData();
    }, []);
    const fetchUserData = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/User/GetUserData`, { headers });
            setPersonalInfo({
                fullName: response.data.userName || '',
                email: response.data.email || '',
                phone: response.data.phoneNumber || '',
                address: '', // Set default address or leave empty
            });
        } catch (error) {
            message.error('Failed to fetch user data');
        }
        finally{
            setCurrentStep(0)
        }
    };
    const handlePrev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleFinish = async (values) => {
        if (!isTermsChecked || !isPrivacyChecked || !isRefundChecked) {
            message.warning('Please accept all policies to proceed.');
            return
        }
        setPaymentInfo(values);
        try {
            // Make API request here
            const response = await axios.post(
                `${baseUrl}/api/Course/BuyCourse`,
                {
                    courseId: id, // Replace with actual course ID
                    personalInfo,
                    paymentData: paymentInfo,
                    acceptTerms: true,
                    acceptPrivacyPolicy: true,
                    acceptRefundPolicy: true,
                }
                ,
                { headers }
            );
            console.log(response);

            setorderdata(response.data);
            setshowDONE(true);
        } catch (error) {
            if (error.response && error.response.status === 400) {
                message.error(error.response.data[0]);

            }
            else {

                message.error("Payment failed, please try again.");
            }
        }
    };

    return (
        <div className="container mx-auto md:my-10 md:p-10 my-2 p-2">


            <div>
                    <Form layout="vertical" onFinish={handleFinish}>
                        <Form.Item
                            name="cardNumber"
                            rules={[
                                { required: true, message: 'Please input your card number!' },
                                { pattern: /^[0-9]{16}$/, message: 'Card number must be 16 digits' }
                            ]}
                        >
                            <Input
                                className='w-full bg-gray-700 text-center text-white placeholder:text-gray-400 hover:bg-gray-600 focus:bg-gray-600 active:bg-gray-600 rounded-full sm:p-4 p-3'
                                placeholder='Card Number'
                            />
                        </Form.Item>

                        <Form.Item
                            name="cardHolderName"
                            rules={[{ required: true, message: 'Please input the card holder name!' }]}
                        >
                            <Input
                                className='w-full bg-gray-700 text-center text-white placeholder:text-gray-400 hover:bg-gray-600 focus:bg-gray-600 active:bg-gray-600 rounded-full sm:p-4 p-3'
                                placeholder='Card Holder Name'
                            />
                        </Form.Item>

                        <div className='grid grid-cols-2 w-full gap-x-10'>
                            <Form.Item
                                name="expiryDate"
                                rules={[
                                    { required: true, message: 'Please input the expiry date!' },
                                    { pattern: /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/, message: 'Invalid expiry dat format (mm/yy)' }
                                ]}
                            >
                                <Input
                                    className='w-full bg-gray-700 text-center text-white placeholder:text-gray-400 hover:bg-gray-600 focus:bg-gray-600 active:bg-gray-600 rounded-full sm:p-4 p-3'
                                    placeholder='Expiry Date'
                                />
                            </Form.Item>
                            <Form.Item
                                name="cvv"
                                rules={[
                                    { required: true, message: 'Please input the CVV!' },
                                    { pattern: /^[0-9]{3}$/, message: 'CVV must be 3 digits' }
                                ]}
                            >
                                <Input
                                    className='w-full bg-gray-700 text-center text-white placeholder:text-gray-400 hover:bg-gray-600 focus:bg-gray-600 active:bg-gray-600 rounded-full sm:p-4 p-3'
                                    placeholder='CVV'
                                    maxLength={3}
                                />
                            </Form.Item>
                        </div>

                        <div className="w-full  flex sm:flex-row flex-col justify-between sm:items-center px-5 sm:gap-0 gap-5 text-white">
                            {/* Terms and Conditions */}
                            <div className="flex items-center  ">
                                <label className="Checkcontainer">
                                    <input
                                        type="checkbox"
                                        checked={isTermsChecked}
                                        onChange={(e) => setIsTermsChecked(e.target.checked)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <p >
                                    Accept{' '}
                                    <span
                                        className="underline cursor-pointer hover:text-[--yellow]"
                                        onClick={() => setTermsModalVisible(true)}
                                    >
                                        Terms and Conditions
                                    </span>
                                </p>
                            </div>

                            {/* Privacy Policy */}
                            <div className="flex items-center ">
                                <label className="Checkcontainer">
                                    <input
                                        type="checkbox"
                                        checked={isPrivacyChecked}
                                        onChange={(e) => setIsPrivacyChecked(e.target.checked)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <p>
                                    Accept{' '}
                                    <span
                                        className="underline cursor-pointer hover:text-[--yellow]"
                                        onClick={() => setPrivacyModalVisible(true)}
                                    >
                                        Privacy Policy
                                    </span>
                                </p>
                            </div>

                            {/* Refund Policy */}
                            <div className="flex items-center ">
                                <label className="Checkcontainer">
                                    <input
                                        type="checkbox"
                                        checked={isRefundChecked}
                                        onChange={(e) => setIsRefundChecked(e.target.checked)}
                                    />
                                    <span className="checkmark"></span>
                                </label>
                                <p >
                                    Accept{' '}
                                    <span
                                        className="underline cursor-pointer hover:text-[--yellow]"
                                        onClick={() => setRefundModalVisible(true)}
                                    >
                                        Refund Policy
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-between w-full mt-6">

                            <button
                                onClick={handlePrev}
                                className="w-full md:w-1/3 rounded-full sm:p-4 p-3 text-center hover:opacity-75 font-semibold  text-gray-200 text-xl placeholder:text-gray-400"
                            >
                                Back
                            </button>
                            <button
                                htmlType="submit"
                                className="w-full md:w-1/3 rounded-full sm:p-4 p-3 text-center hover:opacity-75 font-bold  bg-gray-700 text-white placeholder:text-gray-400"
                            >
                                Pay
                            </button>
                        </div>
                    </Form>
            </div>
            {/* Terms Modal */}
            <Modal
                title={
                    <h2 className="flex items-center justify-center shadow-sm border-b border-gray-200 pb-2 text-lg font-semibold">
                        <FileTextOutlined className="mr-2 text-blue-500" />
                        Terms and Conditions
                    </h2>
                }
                visible={isTermsModalVisible}
                onCancel={() => setTermsModalVisible(false)}
                footer={<p className='border-t border-gray-200 pt-2'><strong>Last Updated:</strong> 19/11/2024</p>}

                width={700}
                centered

            >
                <div className="text-sm  space-y-4 max-h-[80vh] overflow-auto">
                    <p>Welcome to Bosla. By accessing or using our website and services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.</p>

                    <h3 className="font-semibold text-lg">1. Acceptance of Terms</h3>
                    <p>By using Bosla, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree, please do not use our services.</p>

                    <h3 className="font-semibold text-lg">2. User Accounts</h3>
                    <p>You agree to:</p>
                    <ul className="list-disc ml-5">
                        <li>Provide accurate and complete information during the registration process.</li>
                        <li>Maintain the confidentiality of your account credentials and notify us immediately of any unauthorized use.</li>
                        <li>Be responsible for all activities that occur under your account.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">3. Course Access & Usage</h3>
                    <p>Your access to course materials is subject to the following:</p>
                    <ul className="list-disc ml-5">
                        <li>Access and use the course materials solely for personal, non-commercial purposes.</li>
                        <li>Do not reproduce, distribute, or share course materials without explicit permission from Bosla.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">4. Payments and Refunds</h3>
                    <p>All fees must be paid in full at the time of purchase. Refund Policy: No refund or exchange is accepted.</p>

                    <h3 className="font-semibold text-lg">5. Intellectual Property Rights</h3>
                    <p>All content hosted on Bosla is protected by intellectual property laws and is owned by Bosla or its licensors. Unauthorized use is prohibited.</p>

                    <h3 className="font-semibold text-lg">6. User Conduct</h3>
                    <p>You agree to refrain from:</p>
                    <ul className="list-disc ml-5">
                        <li>Harassing, bullying, or intimidating other users.</li>
                        <li>Disrupting the platform's operations or infringing upon other users’ rights.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">7. Termination</h3>
                    <p>We reserve the right to suspend or terminate your account for violations or unlawful activities.</p>

                    <h3 className="font-semibold text-lg">8. Disclaimer of Warranties</h3>
                    <p>Bosla provides its services on an "as-is" basis and makes no warranties regarding course quality or availability.</p>

                    <h3 className="font-semibold text-lg">9. Limitation of Liability</h3>
                    <p>Bosla is not liable for any direct, indirect, or incidental damages arising from the use of its platform or course materials.</p>

                    <h3 className="font-semibold text-lg">10. Governing Law</h3>
                    <p>These Terms and Conditions shall be governed by the laws of Egypt.</p>

                    <h3 className="font-semibold text-lg">11. Changes to Terms</h3>
                    <p>We may modify these terms at any time. Continued use after changes indicates acceptance.</p>

                    <h3 className="font-semibold text-lg">12. Contact Information</h3>
                    <p>For questions, contact us at <a href="mailto:info@boslalearning.com" className="text-blue-900  underline">info@boslalearning.com</a>.</p>

                </div>
            </Modal>

            {/* Privacy Policy Modal */}
            <Modal
                title={
                    <h2 className="flex items-center justify-center shadow-sm border-b border-gray-200 pb-2 text-lg font-semibold">
                        <LockOutlined className="mr-2 text-green-500" />
                        Privacy Policy
                    </h2>
                }
                visible={isPrivacyModalVisible}
                onCancel={() => setPrivacyModalVisible(false)}
                width={700}
                centered
                footer={<p className='border-t border-gray-200 pt-2'><strong>Last Updated:</strong> 19/11/2024</p>}

            >
                <div className="text-sm  max-h-[80vh] overflow-auto space-y-4">
                    <h3 className="font-semibold text-lg">1. Information We Collect</h3>
                    <ul className="list-disc ml-5">
                        <li><strong>Personal Information:</strong> Name, email address, phone number, and payment details.</li>
                        <li><strong>Usage Data:</strong> IP address, browser type, pages visited, etc.</li>
                        <li><strong>Cookies and Tracking:</strong> Used for enhancing user experience.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">2. How We Use Your Information</h3>
                    <ul className="list-disc ml-5">
                        <li>To create and manage your account.</li>
                        <li>To process transactions and communicate updates.</li>
                        <li>To improve our website and services.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">3. Sharing Your Information</h3>
                    <p>We do not sell or rent your personal information. It may be shared with service providers or for legal compliance.</p>

                    <h3 className="font-semibold text-lg">4. Data Security</h3>
                    <p>We implement measures to protect your data but cannot guarantee complete security.</p>

                    <h3 className="font-semibold text-lg">5. Your Rights</h3>
                    <ul className="list-disc ml-5">
                        <li>The right to access, correct, or delete your data.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">6. Third-Party Links</h3>
                    <p>We are not responsible for privacy practices of third-party websites.</p>

                    <h3 className="font-semibold text-lg">7. Contact Us</h3>
                    <p>For questions, contact us at <a href="mailto:info@boslalearning.com" className="text-blue-900 underline">info@boslalearning.com</a>.</p>
                </div>
            </Modal>

            {/* Refund Policy Modal */}
            <Modal
                title={
                    <h2 className="flex items-center justify-center shadow-sm border-b border-gray-200 pb-2 text-lg font-semibold">
                        <UndoOutlined className="mr-2 text-red-500" />
                        Refund Policy
                    </h2>
                }
                visible={isRefundModalVisible}
                onCancel={() => setRefundModalVisible(false)}
                footer={null}
                width={700}
                centered
            >
                <div className="text-sm space-y-4">
                    <h3 className="font-semibold text-lg">No Refunds</h3>
                    <p>Due to the nature of our online courses, we cannot offer refunds after accessing a course.</p>

                    <h3 className="font-semibold text-lg">Exceptions</h3>
                    <ul className="list-disc ml-5">
                        <li>Technical issues that prevent access.</li>
                        <li>Significant changes to course content.</li>
                    </ul>

                    <h3 className="font-semibold text-lg">Requesting a Refund</h3>
                    <p>Contact us at <a href="mailto:info@boslalearning.com" className="text-blue-900 underline">info@boslalearning.com</a> or call +01200224507. Provide details of the issue.</p>
                </div>
            </Modal>
            <Modal
                open={showDONE}
                footer={null}
                width={700}
                centered
            >
                <div className="text-sm flex flex-col items-center justify-center">
                    {/* Icon and Message */}
                    <div className="text-sm space-y-4 flex flex-col items-center justify-center">
                        <i className="fa-regular fa-check-circle text-8xl text-green-400"></i>
                        <p className="text-green-400 text-2xl font-bold anton">{orderdata?.message}</p>
                    </div>

                    {/* Order Code with Copy Button */}
                    <div className="flex items-center space-x-4 mt-7 mb-1">
                        <span className="text-lg font-semibold">
                            Order Code: <span className="font-mono text-gray-800">#{orderdata?.purchaseCode}</span>
                        </span>
                        <button
                            className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-3 py-1 rounded-full shadow-sm flex items-center space-x-2"
                            onClick={() => {
                                navigator.clipboard.writeText(orderdata?.purchaseCode || '');
                                message.success("Order code copied to clipboard!");
                            }}
                        >
                            <i className="fa-regular fa-copy"></i>
                            <span>Copy</span>
                        </button>
                    </div>
                    <p className="text-sm mt-1">
                        Save this code. It may be required to verify ownership if you encounter any issues.
                    </p>

                    {/* Action Buttons */}
                    <div className="flex space-x-4 mt-4">
                        <button
                            className="text-gray-200 hover:text-gray-100  font-bold py-2 px-6 rounded-full transition-colors"
                            onClick={() => navigate(`/home`, { replace: true })}
                        >
                            Back Home
                        </button>
                        <button
                            className="bg-[--yellow] hover:bg-orange-400 text-white font-bold py-2 px-6 rounded-full transition-colors"
                            onClick={() => navigate(`/watch/${id}`, { replace: true })}
                        >
                            Watch Now
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
