import axios from 'axios'
import React, { useEffect, useState } from 'react'
import CourseCard from '../../../Components/Cards/CourseCard.jsx'

export default function WishList({ baseUrl }) {

    const [likedCourses, setLikedCourses] = useState([])

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    }
    async function GetWishList() {
        await axios.get(`${baseUrl}/api/Course/GetUserLikedCourses`, { headers }).then((res) => {
            setLikedCourses(res.data.likedCourses)
        }).catch((err) => {
            

        })
    }

    useEffect(() => {
        GetWishList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='container mx-auto my-10'>
            <div className='grid grid-cols-2 md:grid-cols-3 gap-3'>
                {likedCourses.map((e) => {
                    return <CourseCard course={e} baseUrl={baseUrl} />
                })}
            </div>
        </div>
    )
}
