import { message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const Checkout = ({ baseUrl }) => {
    const orderId = useParams()?.id
    const [OrderData, setOrderData] = useState();
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    }
    const navigate = useNavigate();
    async function getOrder() {
        let data = {
            "orderId": orderId
        }
        await axios.post(`${baseUrl}/api/Payment/GetOrderDetails`, data, { headers })
            .then((res) => {
                setOrderData(res.data)
            }).catch((err) => {
                if (err.response && err.response.status === 401) {
                    navigate('/login');
                }
                else {
                    message.error(err.response.data[0])
                    navigate('/home')
                }
            })
    }


    useEffect(() => {
        getOrder()
        // Remove the query parameters from the URL
    }, []);


    return (
        <div className='min-h-screen flex flex-col items-center justify-center  sm:px-0 px-2'>
            <div className="text-sm flex flex-col items-center justify-center bg-[--secoundry-color] md:p-20 p-3 rounded-xl">
            {/* Icon and Message */}
            <div className="text-sm space-y-4 flex flex-col items-center justify-center">
                <i className="fa-regular fa-check-circle text-8xl text-green-400"></i>
                <p className="text-green-400 text-2xl font-bold anton">{OrderData?.message}</p>
            </div>
                <h1>Order Completed Successfully</h1>
                <div className='flex flex-col'>
               
                </div>
            {/* Order Code with Copy Button */}
            <div className="flex sm:flex-row flex-col items-center space-x-4 mt-7 mb-1 ">
                <span className="text-lg font-semibold sm:text-start text-center ">
                        Order Code: <br className='sm:hidden' /> <span className="font-mono text-white-800 underline ">#{OrderData?.orderId}</span>
                </span>
                
                <button
                    className="bg-gray-200 hover:bg-gray-300 text-gray-700 px-3 py-1 rounded-full shadow-sm flex items-center space-x-2"
                    onClick={() => {
                        navigator.clipboard.writeText(OrderData?.orderId || '');
                        message.success("Order code copied to clipboard!");
                    }}
                >
                    <i className="fa-regular fa-copy"></i>
                    <span>Copy</span>
                </button>
            </div>
            <p className="text-sm mt-1 sm:text-start text-center">
                Save this code. It may be required to verify ownership if you encounter any issues.
            </p>

            {/* Action Buttons */}
            <div className="flex space-x-4 mt-4">
                <button
                    className="text-gray-200 hover:text-gray-100  font-bold py-2 px-6 rounded-full transition-colors"
                    onClick={() => navigate(`/home`, { replace: true })}
                >
                    Back Home
                </button>
                <button
                    className="bg-[--yellow] hover:bg-orange-400 text-white font-bold py-2 px-6 rounded-full transition-colors"
                    onClick={() => navigate(`/watch/${OrderData?.courseId}`, { replace: true })}
                >
                    Watch Now
                </button>
            </div>
        </div>
        </div>
    );
};

export default Checkout;
