import React, { useEffect, useState } from 'react'
import gif from '../../../images/Success.gif'
import { PlusOutlined } from '@ant-design/icons';
import {
  Button, Form, Input, Upload
} from 'antd';
import axios from 'axios';
import './settings.css'


export default function Settings({ baseUrl }) {

  const [providerDataProfile, setProviderDataProfile] = useState()
  const [popUp, setPopUp] = useState(false)
  const [profilePhoto, setProfilePhoto] = useState('')
  const [coverPhoto, setCoverPhoto] = useState('')


  const headers = {
    Authorization: `Bearer ${localStorage.getItem('providerToken')}`,
  }
  const GetMyData = () => {
    axios.get(`${baseUrl}/api/Provider/GetMyProfile`, { headers }).then((res) => {
      setProviderDataProfile(res.data)
      setProfilePhoto(res.data.imageId)
      

    }).catch((err) => {
      

    })
  }

  function uploadCoverPhoto(e) {

    if (e.file.status === 'done') {
      setCoverPhoto(e.file.response.id)

    }

  }
  function uploadProfilePhoto(e) {

    if (e.file.status === 'done') {
      setProfilePhoto(e.file.response.id)

    }
  }
  const EditProfileData = (value) => {
    value.imageId = profilePhoto
    value.coverId = coverPhoto

    axios.post(`${baseUrl}/api/Provider/EditProviderProfile`, value, { headers }).then((res) => {
      GetMyData()
      window.location.reload()
      setPopUp(true)
      setTimeout(() => {
        setPopUp(false)
      }, 1500);

    }).catch((err) => {
      

    })
  }



  useEffect(() => {
    GetMyData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>

      {popUp === true ? <div className='absolute top-0 bottom-0 right-0 left-0  flex justify-center items-center bg-black/75   -z-10'>
        <img src={gif} className='w-56 h-56  rounded-full' alt="" srcset="" />

      </div> : ''}


      <div className='grid grid-cols-1 md:grid-cols-2 mx-auto min-h-screen '>
        <div className='hidden md:block bg-[--bg-secoundry-color] '>
          <div className='relative'>

            <img src={providerDataProfile === undefined || providerDataProfile?.coverId === null ? 'https://th.bing.com/th/id/OIP.3DtAyOwRrErzTWGNKjf3RwHaHa?rs=1&pid=ImgDetMain' : `${baseUrl}/api/Storage/GetImageById?id=${providerDataProfile?.coverId}`} className='w-full h-44 object-cover z-0 absolute' alt={`${providerDataProfile?.title} Cover data`} />
          </div>
          <div className='flex flex-col justify-start items-center 
            '>
            <img src={providerDataProfile === undefined || providerDataProfile?.imageId === null ? 'https://th.bing.com/th/id/OIP.3DtAyOwRrErzTWGNKjf3RwHaHa?rs=1&pid=ImgDetMain' : `${baseUrl}/api/Storage/GetImageById?id=${providerDataProfile?.imageId}`} className='w-56 h-56 rounded-full z-0 object-cover  my-28' alt={`${providerDataProfile?.title} profile data`} />

          </div>
        </div>
        <div className=' p-20 w-100 h-100'>
          {!providerDataProfile ?
            ''
            :
            <Form initialValues={providerDataProfile} layout='vertical' onFinish={EditProfileData}>
              <div className='grid grid-cols-2'>
                <Form.Item label="Upload Profile Photo" valuePropName="fileList" >
                  <Upload
                    maxCount={1}
                    action={`${baseUrl}/api/Storage/UploadImage`}
                    headers={headers}
                    onChange={uploadProfilePhoto}
                    listType="picture-card">
                    <button
                      style={{
                        border: 0,
                        background: 'none',
                      }}
                      type="button"
                    >
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                    </button>
                  </Upload>
                </Form.Item>
                <Form.Item label="Upload Cover Photo" valuePropName="fileList" >
                  <Upload
                    maxCount={1}
                    action={`${baseUrl}/api/Storage/UploadImage`}
                    headers={headers}
                    onChange={uploadCoverPhoto}
                    listType="picture-card">
                    <button
                      style={{
                        border: 0,
                        background: 'none',
                      }}
                      type="button"
                    >
                      <PlusOutlined />
                      <div
                        style={{
                          marginTop: 8,
                        }}
                      >
                        Upload
                      </div>
                    </button>
                  </Upload>
                </Form.Item>
              </div>

              <Form.Item label="Title" name='title'>
                <Input />
              </Form.Item>
              <Form.Item label="About Me" name='aboutMe'>
                <Input />
              </Form.Item>

              <Form.Item label={<>
                <span>Personal Website</span>
                <i class="fa-solid fa-user mx-2 fa-lg"></i>
              </>} name='personalWebsite'>
                <Input />
              </Form.Item>

              <Form.Item label={<>
                <span>Facebook Link</span>
                <i class="fa-brands fa-facebook mx-2 fa-lg"></i>
              </>} name='facebookLink'>
                <Input />
              </Form.Item>


              <Form.Item label={<>
                <span>Linkedin Link</span>
                <i class="fa-brands fa-linkedin mx-2 fa-lg"></i>
              </>} name='linkedInLink'>
                <Input />
              </Form.Item>
              <Button className='w-full bg-[--bg-secoundry-color] text-white text-lg p-5
               hover:bg-[--dark-hover] hover:text-black'  htmlType="submit" >Edit</Button>




            </Form>
          }
        </div>
      </div>
    </>
  )
}







