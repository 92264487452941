import React from 'react'
import { motion } from 'framer-motion';
import { Avatar, Card } from 'antd';

export default function TestimonialCard() {
    const reviews = [
        {
            id: 1,
            avatar: "https://th.bing.com/th/id/OIP.IGNf7GuQaCqz_RPq5wCkPgHaLH?w=119&h=180&c=7&r=0&o=5&pid=1.7",
            text: "Boost your product and service's credibility by adding testimonials from your clients. People love recommendations so feedback from others who've tried it is invaluable.",
            name: "Jasmine Pedraza",
        },
        {
            id: 2,
            avatar: "https://th.bing.com/th/id/OIP.xGgQVj0UucIoY5qF7fqu8wHaLH?w=119&h=180&c=7&r=0&o=5&pid=1.7",
            text: "This is the best service I have ever used! Highly recommend it to everyone.",
            name: "John Doe",
        },
        {
            id: 3,
            avatar: "https://th.bing.com/th/id/OIP.3H7mUZrGow1AhLhF3q5q_QHaJQ?w=139&h=180&c=7&r=0&o=5&pid=1.7",
            text: "Excellent experience! The customer service was top-notch.",
            name: "Jane Smith",
        },
    ];
    return (
        <div className='w-full  '>
            <motion.h1 initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", stiffness: 60 }}
                viewport={{ once: true }} className='font-bold md:text-6xl text-4xl text-center mb-10'>Testimonials and Reviews</motion.h1>

            <motion.div initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", stiffness: 60 }}
                viewport={{ once: true }} className=" h-80 p-3 relative sm:hidden block ">
                <div className=" mx-auto" style={{ scrollSnapType: 'x mandatory' }}>
                    {reviews.map((review, index) => (
                        <div key={review.id} className="">
                            <input
                                className="sr-only peer"
                                type="radio"
                                name="carousel"
                                id={`carousel-${review.id}`}
                                defaultChecked={index === 0} // Check the first item by default
                            />

                            <Card className="w-80 text-center bg-[--offwhite-color] text-[--main-color] opacity-0 transition-all duration-300   shadow-sm peer-checked:opacity-100 peer-checked:z-10 z-0 border-black absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                <img src={review.avatar} alt={review.name} className="mb-5 mx-auto rounded-full h-24 w-24 object-cover" />
                                <h3 className="text-lg md:text-xl">{review.text}</h3>
                                <p className="text-[--yellow] font-bold text-lg mt-2">- {review.name}</p>
                                <div className="absolute top-1/2 w-[23rem] flex justify-between z-20  right-1/2 translate-x-1/2">

                                    <label
                                        htmlFor={`carousel-${reviews[(index - 1 + reviews.length) % reviews.length].id}`}
                                        className="inline-block text-gray-500 opacity-85 cursor-pointer -translate-x-5rounded-full shadow-md active:translate-y-0.5"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </label>
                                    <label
                                        htmlFor={`carousel-${reviews[(index + 1) % reviews.length].id}`}
                                        className="inline-block text-gray-500 opacity-85 cursor-pointer translate-x-5rounded-full shadow-md active:translate-y-0.5"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                                            <path
                                                fillRule="evenodd"
                                                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    </label>
                                </div>

                            </Card>
                        </div>
                    ))}
                </div>
            </motion.div>

            <motion.div initial={{ y: 50, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ type: "spring", stiffness: 60 }}
                viewport={{ once: true }} className=' flex-wrap justify-around  sm:flex hidden'>
                {reviews.map((review, index) => {
                    return <Card className='w-96 text-center bg-[--offwhite-color] text-[--main-color]shadow-sm border-black felx flex-col '>
                        <Avatar className='mb-5' src="https://th.bing.com/th/id/OIP.IGNf7GuQaCqz_RPq5wCkPgHaLH?w=119&h=180&c=7&r=0&o=5&pid=1.7" size={100} alt="" />
                        <h3 className=' md:text-xl  '>{review.text}</h3>
                        <p className='text-[--yellow] font-bold text-lg mt-2'>{review.name}</p>
                    </Card>
                }
                )}
            </motion.div>
        </div>
    )
}
