import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import logo from '../../../images/logo.svg'
export default function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [status, setStatus] = useState(null); // Success or error message state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
            from_name: prevData.name
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setStatus(null); // Reset status before sending

        emailjs.send('service_4rn83ay', 'template_4frsgar', formData, '-9ubbS5Vh4VDBLaB2')
            .then((response) => {
                setStatus({ type: 'success', message: 'Message sent successfully!' });
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    message: ''
                });
            }, (error) => {
                setStatus({ type: 'error', message: 'Failed to send message. Please try again.' });
            });
    };



    return (
        <div className="h-screen justify-center items-center">
            <div className="pt-28">
                <div className="p-4 md:p-8">
                    <div className=" md:w-3/4 lg:w-2/3 xl:w-1/2 md:flex md:justify-between md:items-center flex justify-around items-center  mx-auto  mb-5 bg--500">
                        <h1 className="text-[--offwhite-color]    text-2xl md:text-5xl lg:text-6xl font-bold ">Contact Us</h1>
                    <img src={logo} alt="" className='md:w-60 w-44 ' />
                    </div>
                    <form className="flex flex-col items-center" onSubmit={handleSubmit}>
                        <div className="md:w-3/4 lg:w-2/3 xl:w-1/2">
                         
                            <div className="flex flex-col md:flex-row">
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className="my-2  px-4 py-5 text-center bg-[#f7e7dc] bg-opacity-70   placeholder:text-[#ff971d] text-lg placeholder:text-opacity-70 placeholder:hover:text-opacity-100  text-[#ff971d]  font-bold rounded-3xl  w-full md:w-1/2 md:mr-2 outline-none focus:ring-2 focus:ring-blue-600"
                                    placeholder="Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    className="my-2  px-4 py-5 text-center bg-[#f7e7dc] bg-opacity-70  text- placeholder:text-[#ff971d] text-lg placeholder:text-opacity-70 placeholder:hover:text-opacity-100  text-[#ff971d]  font-bold rounded-3xl  w-full md:w-1/2 md:ml-2 outline-none focus:ring-2 focus:ring-blue-600"
                                    placeholder="Email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <input
                                id="subject"
                                name="subject"
                                type="text"
                                placeholder="Subject"
                                className="my-2 px-4 py-5 text-center bg-[#f7e7dc] bg-opacity-70  text- placeholder:text-[#ff971d] text-lg placeholder:text-opacity-70 placeholder:hover:text-opacity-100  text-[#ff971d]  font-bold rounded-3xl  w-full outline-none focus:ring-2 focus:ring-blue-600"
                                value={formData.subject}
                                onChange={handleChange}
                                required
                            />
                            <textarea
                                id="message"
                                name="message"
                                rows="5"
                                placeholder="Say Something"
                                className="my-2 py-2 px-4 text-center  bg-[#f7e7dc] bg-opacity-70  placeholder:text-[#ff971d] text-lg placeholder:text-opacity-70 placeholder:hover:text-opacity-100 
                    
                                   text-[#ff971d]  font-bold rounded-3xl  w-full outline-none focus:ring-2 focus:ring-blue-600"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                            <div className='text-end w-full'>
                                {/* <button
                                    type="button"
                                    onClick={handleReset}
                                    className="text-md mt-5 py-10 me-10 text-blue-600 hover:text-blue-700 transition duration-300 ease-in-out"
                                >
                                    Re-set
                                </button> */}
                                <button
                                    type="submit"
                                    className='text-xl py-2 rounded-lg text-center  px-5 bg-[--offwhite-color] hover:bg-[#fff6ef] text-[#758694] mt-8 cursor-pointer capitalize font-semibold'>
                              
                                    Send Message
                                </button>
                                
                            </div>
                            {status && (
                                <div className={`my-4 p-4 text-center py-10 ${status.type === 'success' ? 'bg-green-500 text-white' : 'bg-red-500 text-white'}`}>
                                    {status.message}
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
