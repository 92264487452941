import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Modal, Form, Input, Button, Tooltip, Upload, List, Steps, Select, message, Popconfirm } from 'antd';
import { FilePdfFilled, MinusCircleOutlined, PlayCircleOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons';

export default function Course({ baseUrl }) {
  const { id } = useParams();
  const [Chapters, setChapters] = useState([]);
  const [pdfId, setPdfId] = useState('');
  const [load, setLoad] = useState(false);
  const [courseDetails, setCourseDetails] = useState({});
  const [showAddModal, setShowAddModal] = useState(false);
  const [quizData, setQuizData] = useState('');
  const [chapterScores, setChapterScores] = useState('');
  const [editChapter, setEditChapter] = useState(null); // State for the chapter being edited
  const [editModalVisible, setEditModalVisible] = useState(false); // State to control edit modal visibility
  const [addVideoModalVisible, setAddVideoModalVisible] = useState(false); // State to control Add Video modal visibility
  const [addFileVisible, setAddFileVisible] = useState(false); // State to control Add Video modal visibility
  const [addQuizVisible, setAddQuizVisible] = useState(false); // State to control Add Video modal visibility
  const [showQuizVisible, setShowQuizVisible] = useState(false); // State to control Show Quiz modal visibility
  const [showUserScoresVisible, setShowUserScoresVisible] = useState(false); // State to control Show Quiz modal visibility
  const [list, setlist] = useState();
  const [chapterID, setchapterID] = useState();
  const [editForm] = Form.useForm(); // Form instance for editing
  const [form] = Form.useForm();
  const headers = {
    Authorization: `Bearer ${localStorage.getItem('providerToken')}`,
  }
  useEffect(() => {
    getChapters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, baseUrl]);
  let navigate = useNavigate()





  const onFinishFailed = (errorInfo) => {
  };


  //Upload Pdf 

  const props = {
    name: 'File',
    action: `${baseUrl}/api/Storage/UploadPDF`,
    headers: headers,
    onChange(info) {
      setlist(info.fileList)
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setPdfId(info?.file?.response?.id)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        if (info?.file?.error?.status === 401) {
          navigate('/login')
          message.warning(`This account is open in another device`);

        }
      }
    },
  };


  //Show Quiz
  function showQuiz(e) {
    setShowQuizVisible(true)
    setchapterID(e.chapterId)
    getQuizQuestions(e.quizId)
  }


  //Show User Scores

  function showUserScores(e) {
    setShowUserScoresVisible(true)
    setchapterID(e.chapterId)
    getChapterScores(e.chapterId)
  }


  const getChapterScores = async (chapterId) => {
    await axios.get(`${baseUrl}/api/Quiz/GetChapterScores?ChapterId=${chapterId}`, { headers }).then((res) => {

      setChapterScores(res.data)
    }).catch((err) => {


    })
  }




  async function getQuizQuestions(quizId) {
    await axios.get(`${baseUrl}/api/Quiz/GetQuizById?QuizId=${quizId}`, { headers }).then((res) => {
      setQuizData(res.data)

    }).catch((err) => {


    })
  }

  //Add Quiz 
  function openAddQuizModal(e) {
    form.resetFields();
    setAddQuizVisible(true)
    setchapterID(e.chapterId)

  }

  const addQuiz = async (values) => {
    await axios.post(`${baseUrl}/api/Quiz/AddQuiz`, {
      chapterId: chapterID,
      title: values.title,
      questions: values.questions
    }, { headers }).then((res) => {
      setAddQuizVisible(false)
      form.resetFields();
      getChapters()
    }).catch((err) => {



    })


  }

  // Add Video To Chapter 

  function openAddFileModal(e) {
    setAddFileVisible(true)
    setchapterID(e.chapterId)
  }
  const AddFileToChapter = (values) => {
    axios.post(`${baseUrl}/api/Content/AddFileToChapter`, {
      chapterId: chapterID,
      name: values.name,
      pdfLink: pdfId,
    }, { headers }).then(response => {

      setAddFileVisible(false);

    })
      .catch(error => {
        console.error("Error Adding Chapter:", error);
      });



  };



  function openAddVideoModal(e) {
    setAddVideoModalVisible(true)
    setchapterID(e.chapterId)

  }
  const AddVideoToChapter = (values) => {
    axios.post(`${baseUrl}/api/Content/AddVideoToChapter`, {
      chapterId: chapterID,
      name: values.name,
      videoLink: values.videoLink,
    }, { headers }).then(response => {

      setAddVideoModalVisible(false);
      getChapters()
    })
      .catch(error => {
        console.error("Error Adding Chapter:", error);
      });



  };


  function getChapters(params) {
    // Fetch course details
    axios.get(`${baseUrl}/api/Content/GetCourseData?CourseId=${id}`, { headers })
      .then(response => {
        setCourseDetails(response.data);
        setChapters(response.data.chapters); // Assuming response.data contains the chapters array
      })
      .catch(error => {
        console.error("Error fetching course details:", error);
      });

  }
  const handleAddChapter = (values) => {

    axios.post(`${baseUrl}/api/Chapter/AddChapter`, {
      name: values.name,
      decription: values.description,
      courseId: id,
    }, { headers })
      .then(response => {
        getChapters()
        setShowAddModal(false);
        form.resetFields();
      })
      .catch(error => {
        console.error("Error adding chapter:", error);
      });
  };

  function showEditModal(chapter) {
    setEditChapter(chapter);

    editForm.setFieldsValue({
      name: chapter.name,
      description: chapter.description
    });
    setEditModalVisible(true);
  }

  const handleEditChapter = (values) => {
    axios.put(`${baseUrl}/api/Chapter/EditChapter`, {
      id: editChapter.chapterId,
      name: values.name,
      description: values.description,
      courseId: editChapter.courseId,
    }, { headers })
      .then(response => {
        getChapters();
        setEditModalVisible(false);
        setEditChapter(null);
      })
      .catch(error => {
        console.error("Error editing chapter:", error);
      });
  };
  const deleteChapter = (chapterId) => {
    axios.delete(`${baseUrl}/api/Chapter/DeleteChapter?Id=${chapterId}`, { headers })
      .then(response => {
        getChapters(); // Refresh the chapter list
      })
      .catch(error => {
        console.error("Error deleting chapter:", error);
      });
  };

  return <>
    <section className=''>
      <div className="w-full h-[250px] relative bg-gray-500">
        <img
          src={`${baseUrl}/api/Storage/GetImageById?id=${courseDetails?.cover}` || "default-image.jpg"}
          alt='cover'
          className="w-full h-full rounded-tl-lg brightness-95 rounded-tr-lg object-cover"
        />
        <div className='absolute top-1/2 right-0 left-0 text-center'>
          <h1 className='text-3xl fontb'>{courseDetails?.courseName}</h1>
        </div>
      </div>
      <div className="flex flex-col md:col-span-2 md:row-span-2  shadow-lg  overflow-auto">
        <div className='border-b border-gray-100 flex justify-between w-full items-center bgda px-5 py-3'>
          <div className="font-semibold">Courses</div>
          <Button type="primary" onClick={() => setShowAddModal(true)}>
            <i className='fa fa-plus me-1'></i>
            Create Chapter
          </Button>
        </div>
        <div className="flex-grow">
          <div className="flex-auto block py-8 pt-6 px-9">
            <div className="overflow-x-auto">
              {Chapters?.map((chapter, index) => (
                <table className="w-full my-0 align-middle text-dark border-neutral-200 border-b last:border-0">
                  <thead className="align-bottom border-dashed border-t-[1px] first:border-t-0  ">
                    <tr className="font-semibold  text-[0.95rem] text-secondary-dark pb-3">
                      <th className="text-start w-2/5  "></th>
                      <th className="text-center w-1/5 ">Description</th>
                      <th className="text-center w-1/5 ">Add Content</th>
                      <th className="text-center w-1/5 ">Actions</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      key={index}
                      className="  rounded-xl  "
                    >
                      <td className="p-3 pl-0">

                        <p className="mb-1 font-semibold transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary">
                          {index + 1}) {chapter.chapterName}
                        </p>


                      </td>

                      <td className="p-3 pr-0 text-center">
                        <span className="font-semibold text-light-inverse text-md/normal">{chapter.description}</span>
                      </td>
                      <td className='text-center'>
                        <Tooltip title="Video">
                          <Button onClick={() => {
                            openAddVideoModal(chapter)
                          }} type='link' className='text-white w-0'>

                            <i class="fa-solid fa-video cursor-pointer "></i>
                          </Button>
                        </Tooltip>

                        <Tooltip title="File">
                          <Button onClick={() => {
                            openAddFileModal(chapter)
                          }} type='link' className='text-white w-0'>

                            <i class="fa-solid fa-file cursor-pointer"></i>
                          </Button>

                        </Tooltip>
                        <Tooltip title="Quiz">
                          <Button onClick={() => {
                            openAddQuizModal(chapter)
                          }} type='link' className='text-white w-0'>

                            <i class="fa-solid fa-question cursor-pointer"></i>
                          </Button>
                        </Tooltip>
                      </td>
                      <td className="p-3 text-center space-x-3">
                        <Tooltip title="Edit Chapter">
                          <i className="fa fa-edit text-blue-500 hover:text-blue-600 cursor-pointer text-lg" onClick={(e) => { e.stopPropagation(); showEditModal(chapter); }}></i>
                        </Tooltip>
                        <Tooltip title="Delete Chapter">
                          <Popconfirm
                            title="Are you sure you want to delete this chapter?"
                            onConfirm={(e) => {
                              e?.stopPropagation(); // Prevent event bubbling
                              deleteChapter(chapter.chapterId); // Call the delete function
                            }}
                            onCancel={(e) => e?.stopPropagation()} // Prevent event bubbling on cancel
                            okText="Yes"
                            cancelText="No"
                          >
                            <i className="fa fa-trash text-red-500 hover:text-red-600 cursor-pointer text-lg"></i>
                          </Popconfirm>
                        </Tooltip>
                      </td>

                    </tr>
                    <tr
                      key={index}
                      className="  rounded-xl "
                    >
                      <td className="my-2">
                        <Steps size="small" direction='vertical' >
                          {chapter.videoLinks.map((video, index) => (
                            <Steps.Step icon={<PlayCircleOutlined />} title={video.name} key={index} />
                          ))}
                          {chapter.pdfLinks.map((pdf, index) => (
                            <Steps.Step icon={<FilePdfFilled />} title={pdf.name} key={index} />
                          ))}
                        </Steps>
                        {
                          chapter.quizId === null ? "" :
                            <>
                              <Tooltip title="Show Quiz">
                                <Button onClick={() => {
                                  showQuiz(chapter)
                                }}>
                                  <i class="fa-regular fa-question fa-xl cursor-pointer"></i>
                                </Button>

                              </Tooltip>
                              <Tooltip title="Show User Scores" className='mx-4'>
                                <Button onClick={() => {
                                  showUserScores(chapter)
                                }}>
                                  <i class="fa-solid fa-user-pen"></i>
                                </Button>

                              </Tooltip>
                            </>


                        }
                      </td>


                    </tr>
                  </tbody>


                </table>
              ))}
            </div>
          </div>
        </div>
      </div>

    </section>


    {/* Modals */}
    <Modal
      visible={showAddModal}
      onCancel={() => setShowAddModal(false)}
      footer={null}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleAddChapter}
      >
        <Form.Item
          name="name"
          label="Chapter Name"
          rules={[{ required: true, message: 'Please enter the chapter name' }]}
        >
          <Input placeholder="Enter chapter name" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-end space-x-3">
            <Button onClick={() => setShowAddModal(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Add Chapter
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>

    <Modal
      title="Edit Chapter"
      visible={editModalVisible}
      onCancel={() => setEditModalVisible(false)}
      footer={null}
    >
      <Form
        form={editForm}
        layout="vertical"
        onFinish={handleEditChapter}
      >
        <Form.Item
          name="name"
          label="Chapter Name"
          rules={[{ required: true, message: 'Please enter the chapter name' }]}
        >
          <Input placeholder="Enter chapter name" />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please enter the description' }]}
        >
          <Input.TextArea placeholder="Enter description" />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-end space-x-3">
            <Button onClick={() => setEditModalVisible(false)}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>

    <Modal
      open={addVideoModalVisible}
      onCancel={() => setAddVideoModalVisible(false)}
      footer={null}
      closeIcon={false}
    >
      <Form

        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={AddVideoToChapter}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input your course name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Video Link"
          name="videoLink"
          rules={[
            {
              required: true,
              message: 'Please input your Video Link!',
            },
          ]}
        >
          <Input />
        </Form.Item>



        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>

    <Modal
      visible={addFileVisible}
      onCancel={() => setAddFileVisible(false)}
      footer={null}
    >
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 600,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={AddFileToChapter}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input your course name!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="pdfLink"
          label="Pdf Link"
          rules={[{ required: true, message: 'Please upload a Pdf Link' }]}
        >
          <Upload fileList={list}{...props} id='upload' className='' accept='.pdf , .xlsx , .xls' maxCount={1}>
            <Button size='large' type='default' icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>



        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            {load ? 'Uploading...' : 'Submit'}

          </Button>
        </Form.Item>
      </Form>
    </Modal>



    <Modal
      visible={addQuizVisible}
      onCancel={() => setAddQuizVisible(false)}
      width={600}
      closeIcon={false}
      footer={null}
    >
      <Form form={form} name="chapter_form" onFinish={addQuiz} requiredMark={false} initialValues={{
        title: '',
        questions: [{ title: '', option1: '', option2: '', option3: '', option4: '', answer: '' }]
      }}>
        {/* Chapter Title */}
        <Form.Item label="Quiz Title" name="title" rules={[{ required: true, message: 'Please input the Quiz title!' }]}>
          <Input />
        </Form.Item>

        {/* Questions List */}
        <Form.List name="questions">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <div className=''>
                  <div className='flex w-full justify-between items-center'>
                    <p className='text-white text-2xl py-2'>Q1)</p>
                  <Button type='link' danger className=''>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                    <p className='text-2xl'></p>
                  </Button>
                  </div>
                  <div className="flex gap-5">
                    {/* Question Title */}
                    {/* Remove Question Button */}
                    <Form.Item
                      className='w-2/3'
                      {...restField}
                      name={[name, 'title']}
                      fieldKey={[fieldKey, 'title']}
                      rules={[{ required: true, message: 'Please input the question title!' }]}
                    >
                      <Input placeholder="Question Title" />
                    </Form.Item>
                    {/* Answer */}
                    <Form.Item
                      className='w-1/3'
                      {...restField}
                      name={[name, 'answer']}
                      fieldKey={[fieldKey, 'answer']}
                      rules={[{ required: true, message: 'Please select answer!' }]}
                    >
                      <Select options={[{ label: '1', value: 0 }, { label: '2', value: 1 }, { label: '3', value: 2 }, { label: '4', value: 3 }]} placeholder="Answer" allowClear />
                    </Form.Item>
                  </div>

                  {/* Option 1 */}
                  <Form.Item
                    {...restField}
                    name={[name, 'option1']}
                    fieldKey={[fieldKey, 'option1']}
                    rules={[{ required: true, message: 'Please input option 1!' }]}
                  >
                    <Input placeholder="Option 1" />
                  </Form.Item>

                  {/* Option 2 */}
                  <Form.Item
                    {...restField}
                    name={[name, 'option2']}
                    fieldKey={[fieldKey, 'option2']}
                    rules={[{ required: true, message: 'Please input option 2!' }]}
                  >
                    <Input placeholder="Option 2" />
                  </Form.Item>

                  {/* Option 3 */}
                  <Form.Item
                    {...restField}
                    name={[name, 'option3']}
                    fieldKey={[fieldKey, 'option3']}
                    rules={[{ required: true, message: 'Please input option 3!' }]}
                  >
                    <Input placeholder="Option 3" />
                  </Form.Item>

                  {/* Option 4 */}
                  <Form.Item
                    {...restField}
                    name={[name, 'option4']}
                    fieldKey={[fieldKey, 'option4']}
                    rules={[{ required: true, message: 'Please input option 4!' }]}
                  >
                    <Input placeholder="Option 4" />
                  </Form.Item>




                </div>
              ))}

              {/* Add Question Button */}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                  Add Question
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* Submit Button */}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
    <Modal
      title={<div className='text-2xl'>{quizData.title}</div>}
      visible={showQuizVisible}
      onCancel={() => setShowQuizVisible(false)}
      footer={null}
      width={700}
      centered
    >
      <List
        // bordered
        dataSource={quizData.questions}
        renderItem={(item, index) => {

          return <div className='border  p-2 rounded-xl my-5'>
            <List.Item>
              <p className='text-white text-xl'>{item.title}</p>
            </List.Item>
            <List.Item>
              <p className={`${item.answer === 0 ? 'text-white text-lg  bg-green-300 rounded-md p-3 w-full' : ' text-white text-lg p-3'}`}> {item.option1}</p>
            </List.Item>
            <List.Item>
              <p className={`${item.answer === 1 ? 'text-white text-lg bg-green-300 rounded-md p-3 w-full' : ' text-white text-lg p-3'}`}>  {item.option2}</p>
            </List.Item>
            <List.Item>
              <p className={`${item.answer === 2 ? 'text-white text-lg bg-green-300 rounded-md p-3 w-full' : ' text-white text-lg p-3'}`}>  {item.option3}</p>
            </List.Item>
            <List.Item>
              <p className={`${item.answer === 3 ? 'text-white text-lg bg-green-300 rounded-md p-3 w-full' : ' text-white text-lg p-3'}`}>  {item.option4}</p>
            </List.Item>


          </div>

        }


        }
      />
    </Modal>

    <Modal
      title={<div className='text-3xl'>Chapter Scores</div>}
      visible={showUserScoresVisible}
      onCancel={() => setShowUserScoresVisible(false)}
      footer={null}
    >
      <List


        bordered
        dataSource={chapterScores}
        renderItem={(item, index) => {

          return <>
            <List.Item>
              <p className='text-white text-xl'>{item.userId} :   {item.score} / {item.totalQuestions}</p>
            </List.Item>



          </>

        }


        }
      />
    </Modal>




  </>
}
