// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
  display: flex;
  position: relative;
  background-color: #fff;
  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);
  padding: 0.75rem;
  border-radius: 99px;
}

.tabs * {
  z-index: 2;
}

.containe input[type="radio"] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 80px;
  font-size: .9rem;
  color: black;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}


.containe input[type="radio"]:checked + label {
  color: #185ee0;
}

.containe input[type="radio"]:checked + label > .notification {
  background-color: #185ee0;
  color: #fff;
  margin: 0px;
}

.containe input[id="radio-1"]:checked ~ .glider {
  transform: translateX(-65%);
  width: 80px;
}

.containe input[id="radio-2"]:checked ~ .glider {
  transform: translateX(55%);
}

.containe input[id="radio-3"]:checked ~ .glider {
  transform: translateX(200%);
}

.glider {
  position: absolute;
  display: flex;
  height: 30px;
  width: 90px;
  background-color: #e6eef9;
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}


`, "",{"version":3,"sources":["webpack://./src/Components/RadioToggle/toggle.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,mFAAmF;EACnF,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,+BAA+B;AACjC;;;AAGA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,WAAW;EACX,WAAW;AACb;;AAEA;EACE,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,yBAAyB;EACzB,UAAU;EACV,mBAAmB;EACnB,0BAA0B;AAC5B","sourcesContent":[".tabs {\n  display: flex;\n  position: relative;\n  background-color: #fff;\n  box-shadow: 0 0 1px 0 rgba(24, 94, 224, 0.15), 0 6px 12px 0 rgba(24, 94, 224, 0.15);\n  padding: 0.75rem;\n  border-radius: 99px;\n}\n\n.tabs * {\n  z-index: 2;\n}\n\n.containe input[type=\"radio\"] {\n  display: none;\n}\n\n.tab {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 30px;\n  width: 80px;\n  font-size: .9rem;\n  color: black;\n  font-weight: 500;\n  border-radius: 99px;\n  cursor: pointer;\n  transition: color 0.15s ease-in;\n}\n\n\n.containe input[type=\"radio\"]:checked + label {\n  color: #185ee0;\n}\n\n.containe input[type=\"radio\"]:checked + label > .notification {\n  background-color: #185ee0;\n  color: #fff;\n  margin: 0px;\n}\n\n.containe input[id=\"radio-1\"]:checked ~ .glider {\n  transform: translateX(-65%);\n  width: 80px;\n}\n\n.containe input[id=\"radio-2\"]:checked ~ .glider {\n  transform: translateX(55%);\n}\n\n.containe input[id=\"radio-3\"]:checked ~ .glider {\n  transform: translateX(200%);\n}\n\n.glider {\n  position: absolute;\n  display: flex;\n  height: 30px;\n  width: 90px;\n  background-color: #e6eef9;\n  z-index: 1;\n  border-radius: 99px;\n  transition: 0.25s ease-out;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
