import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Input, Select, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const { TextArea } = Input;
const { Option } = Select;

const CreateCourseModal = ({ visible, onClose, baseUrl, fetchCourses }) => {
    const [form] = Form.useForm();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [imageId, setImageId] = useState(null);
    const [descriptionImageId, setDescriptionImageId] = useState(null);
    const [secondaryImageId, setSecondaryImageId] = useState(null);

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('providerToken')}`,
    };

    useEffect(() => {
        GetCategoryList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function GetCategoryList() {
        try {
            const response = await axios.get(`${baseUrl}/api/Category/GetCategoryList`);
            setCategories(response.data);
        } catch (error) {
            message.error('Failed to load categories');
        }
    }

    const handleImageUpload = async ({ file, onProgress, onSuccess, onError }, setImageCallback) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${baseUrl}/api/Storage/UploadImage`, formData, {
                headers,
                onUploadProgress: (event) => {
                    const percent = Math.round((event.loaded / event.total) * 100);
                    onProgress({ percent });
                },
            });

            setImageCallback(response.data.id);
            message.success('Image uploaded successfully');
            onSuccess(response.data);
        } catch (error) {
            message.error('Failed to upload image');
            onError(error);
        }
    };

    const handleCreate = () => {
        form.validateFields().then(values => {
            if (!imageId || !descriptionImageId) {
                message.error('Please upload all required images');
                return;
            }

            setLoading(true);
            axios.post(`${baseUrl}/api/Course/AddCourse`, {
                ...values,
                cover: imageId,
                descriptionImageId,
                secondaryImageId,
                providerId: localStorage.getItem('providerID'),
            }, { headers })
                .then(response => {
                    message.success('Course created successfully');
                    form.resetFields();
                    setImageId(null);
                    setDescriptionImageId(null);
                    setSecondaryImageId(null);
                    fetchCourses();
                    onClose();
                })
                .catch(error => {
                    message.error('Failed to create course');
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    };

    return (
        <Modal
        centered 
        width={700}
            // title="Create New Course"
            visible={visible}
            onCancel={onClose}
            closeIcon={false}
            footer={[
                <Button key="back" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleCreate}>
                    Create
                </Button>,
            ]}
        >
        
            <Form
                form={form}
                layout="vertical"
                name="createCourseForm"
                requiredMark={false}
                size='large'
            >
                <Form.Item
                    name="name"
                    label="Course Name"
                    rules={[{ required: true, message: 'Please enter the course name' }]}
                >
                    <Input placeholder="Enter course name" />
                </Form.Item>
                <Form.Item
                    name="descriptionText"
                    label="Description Text"
                    rules={[{ required: true, message: 'Please enter the course description' }]}
                >
                    <TextArea rows={4} placeholder="Enter course description" />
                </Form.Item>

                <Form.Item
                    name="categoryId"
                    label="Category"
                    rules={[{ required: true, message: 'Please select a category' }]}
                >
                    <Select placeholder="Select a category">
                        {categories.map(category => (
                            <Option key={category.id} value={category.id}>
                                {category.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="price"
                    label="Course Price"
                    rules={[{ required: true, message: 'Please enter the course price' }]}
                >
                    <Input placeholder="Enter course price" />
                </Form.Item>
                <div className='flex items-center w-full gap-5'>
                    <Form.Item
                        name="cover"
                        className='w-1/3'
                        label="Cover Image"
                        rules={[{ required: true, message: 'Please upload a cover image' }]}
                    >
                        <Upload
                            name="cover"
                            listType="picture"
                            maxCount={1}
                            customRequest={(options) => handleImageUpload(options, setImageId)}
                            showUploadList={{ showRemoveIcon: false }}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="descriptionImageId"
                        className='w-1/3'
                        label="Description Image"
                        rules={[{ required: true, message: 'Please upload a description image' }]}
                    >
                        <Upload
                            name="descriptionImage"
                            
                            listType="picture"
                            maxCount={1}
                            customRequest={(options) => handleImageUpload(options, setDescriptionImageId)}
                            showUploadList={{ showRemoveIcon: false }}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        name="secondaryImageId"
                        className='w-1/3'
                        label="Secondary Image"
                        rules={[{ required: false }]}
                    >
                        <Upload
                            name="secondaryImage"
                            listType="picture"
                            maxCount={1}
                            customRequest={(options) => handleImageUpload(options, setSecondaryImageId)}
                            showUploadList={{ showRemoveIcon: false }}
                        >
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    
                </div>
            

           
            </Form>
        </Modal>
    );
};

export default CreateCourseModal;
