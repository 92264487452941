import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Option from './Option'; // Import the Option component

const Quiz = ({ baseUrl, id }) => {
    const [quizData, setQuizData] = useState();
    const [userAnswers, setUserAnswers] = useState({});
    const [score, setScore] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const headers = {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    };

    useEffect(() => {
        getQuizData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getQuizData() {
        try {
            const res = await axios.get(`${baseUrl}/api/Quiz/GetQuizById?QuizId=${id}`, { headers });
            const quiz = res.data;
            setQuizData(quiz);

            if (quiz.score !== null) {
                setScore(quiz.score);
                setSubmitted(true);
                setUserAnswers(quiz.questions.reduce((acc, question) => {
                    acc[question.id] = question.userAnswer || null;
                    return acc;
                }, {}));
            }
        } catch (err) {
        }
    }

    const handleAnswerChange = (questionId, selectedAnswer) => {
        setUserAnswers({
            ...userAnswers,
            [questionId]: selectedAnswer,
        });
    };

    const handleSubmit = async () => {
        const questionAnswers = quizData.questions.map((question) => ({
            questionId: `${question.id}`,
            answer: userAnswers[question.id] || null,
        }));

        let calculatedScore = 0;
        let feedbackArray = [];

        quizData.questions.forEach((question) => {
            const userAnswer = userAnswers[question.id];
            const isCorrect = parseInt(userAnswer) === question.answer; // Compare as int
            if (isCorrect) calculatedScore++;

            feedbackArray.push({
                question: question.title,
                userAnswer,
                correctAnswer: question.answer,
                isCorrect,
            });
        });

        setScore(calculatedScore);
        setSubmitted(true);

        try {
            await axios.post(
                `${baseUrl}/api/Quiz`,
                {
                    quizId: id,
                    score: calculatedScore,
                    questionAnswers,
                },
                { headers }
            );
        } catch (err) {
        }
    };

    return (
        <div className="quiz-container">
            {quizData?.questions?.map((q, questionIndex) => (
                <div className="max-w-md my-5" key={q.id}>
                    <h1 className="my-3">{questionIndex + 1}) {q.title}</h1>
                    <div className="space-y-4">
                        {[q.option1, q.option2, q.option3, q.option4].map((option, index) => (
                            <Option
                                key={index}
                                index={index}
                                op={option}
                                questionIndex={questionIndex}
                                questionId={q.id}
                                handleAnswerChange={handleAnswerChange}
                                submitted={submitted}
                                userAnswer={userAnswers[q.id]}
                                correctAnswer={q.answer}
                            />
                        ))}
                    </div>
                </div>
            ))}

            {submitted ? (
                <div className="score-feedback mt-5">
                    <h1>Your Score: {score} / {quizData?.questions?.length}</h1>
                </div>
            ) : (
                <button
                    onClick={handleSubmit}
                    className="px-4 py-2 bg-blue-500 text-white rounded-lg mt-5"
                >
                    Submit Quiz
                </button>
            )}
        </div>
    );
};

export default Quiz;
