import React, { useEffect, useState } from 'react';
import axios from 'axios';
import img from './Auth.svg';
import logo from '../../images/logo.svg'
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd'
import RadioToggle from '../../Components/RadioToggle/RadioToggle';
export default function SignUp({ baseUrl }) {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [registrationType, setRegistrationType] = useState('user');
  const Error = (message) => {
    api.error({
      type: 'error',
      message: `${message}`,
      placement: 'topRight',
    });
  };
  const Succsess = (message) => {
    api.success({
      message: `${message}`,
      placement: 'topRight',
    });
  };
  const handleSignUp = async (e) => {
    e.preventDefault();
    const endpoint = registrationType === 'user' ? '/api/User/RegisterAsUser' : '/api/User/RegisterAsProvider';
    try {
      const response = await axios.post(`${baseUrl}${endpoint}`, {
        username,
        email,
        password,
      });

      if (response.status === 200) {
        Succsess('Account Created Succsessfully')
        
        
        // Redirect or perform other actions on successful sign up
        navigate('/login');
      }
    } catch (error) {
      

      if (error.response && error.response.status === 400) {
        Error(error?.response?.data.errors[0] ? error?.response?.data.errors[0] : 'Email already in use or invalid data');
      } else {
        Error('Sign up failed');
      }
    }
  };


  return (
    <div className='px-4 sm:px-10 w-screen h-screen flex flex-col sm:flex-row justify-center sm:justify-between items-center'>
      {contextHolder}
      <div className='flex flex-col w-full sm:w-1/2 gap-5 sm:relative absolute bottom-0 top-0 justify-center z-10 backdrop-blur-sm sm:p-0 px-5'>
        <div className='container mx-auto'>
          <img src={logo} alt="Logo" className='cursor-pointer' onClick={() => { navigate('/') }} />
        </div>
        <div className='w-full flex justify-center  '>
          <RadioToggle setRegistrationType={setRegistrationType} />
        </div>
        <form onSubmit={handleSignUp} className='flex flex-col gap-4 sm:gap-5 '>
          <input
            type="text"
            placeholder='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className='bg-white w-full px-4 py-3 sm:py-5 rounded-full text-black text-center'
          />
          <input
            type="text"
            placeholder='Email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='bg-white w-full px-4 py-3 sm:py-5 rounded-full text-black text-center'
          />
          <input
            type="password"
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className='bg-white w-full px-4 py-3 sm:py-5 rounded-full text-black text-center'
          />
          <div className='text-center'>
            <button type="submit" className='w-full sm:w-auto text-2xl sm:text-5xl text-[--yellow] hover:text-yellow-400 anton'>
              Sign Up
            </button>
            <p className="mt-5 text-center text-sm">
              Already have an account?
              <p
                href="#"
                className="ms-2 font-semibold leading-6 text-[--yellow] hover:text-yellow-400"
                onClick={() => { navigate('/login') }}
              >
                Log in
              </p>
            </p>
          </div>
        </form>
      </div>
      <img src={img} alt="Auth illustration" className=' sm:block h-full sm:w-1/2 sm:scale-100 scale-150 sm:opacity-100 opacity-20 object-contain' />
    </div>
  );
}
