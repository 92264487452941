import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './NavBar.css';
import logo from '../../images/logo.svg'
import axios from 'axios';

const ProvdierNav = ({ baseUrl }) => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [providerDataProfile, setProviderDataProfile] = useState()


    const headers = {
        Authorization: `Bearer ${localStorage.getItem('providerToken')}`,
    }
    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };



    const GetMyData = () => {
        axios.get(`${baseUrl}/api/Provider/GetMyProfile`, { headers }).then((res) => {
            setProviderDataProfile(res.data)

        }).catch((err) => {

        })
    }


    function handleNavigate(path) {
        navigate(path)
        setIsOpen(false)
    }

    useEffect(() => {
        GetMyData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return (
        <nav className={`py-2.5  bg-[#3c4f5d] shadow  w-full  z-50 `}>
            <div className="px-10 flex w-full justify-between items-center contec mx-auto">
                <div className="flex items-center">
                    <span className="self-center text-xl w-40  font-semibold whitespace-nowrap ">
                        <img src={logo} alt="" className='md:w-auto  drop-shadow-lg  sm:mx-0 mx-auto object-contain' />
                    </span>
                </div>

                <div className="relative inline-block">
                    <img
                        alt="tania andrew"
                        
                        src={providerDataProfile?.imageId === undefined || providerDataProfile?.imageId === null ? 'https://th.bing.com/th/id/OIP.3DtAyOwRrErzTWGNKjf3RwHaHa?rs=1&pid=ImgDetMain' : `${baseUrl}/api/Storage/GetImageById?id=${providerDataProfile?.imageId}`} 
                        className="h-11 w-11 cursor-pointer rounded-full object-cover object-center"
                        onClick={toggleMenu}
                    />
                 
                    {isOpen && (
                        <ul
                            role="menu"
                            className="absolute right-0 z-10 flex min-w-[180px] flex-col gap-2 overflow-auto rounded-md border border-gray-800 border-blue-gray-50 bg-gray-800 p-3 font-sans text-sm font-normal text-blue-gray-500 shadow-lg shadow-blue-gray-500/10 focus:outline-none"
                        >
                            <button
                                tabIndex="-1"
                                role="menuitem"
                                onClick={() => handleNavigate('profile')}
                                className="flex w-full cursor-pointer select-none items-center gap-2 rounded-md px-3 pt-[9px] pb-2 text-start leading-tight outline-none transition-all hover:bg-gray-400 hover:bg-opacity-80  focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                    className="h-4 w-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                                    ></path>
                                </svg>
                                <p className="block font-sans text-sm font-normal leading-normal text-inherit antialiased" >
                                    My Profile
                                </p>
                            </button>
                            <button
                                tabIndex="-1"
                                role="menuitem"
                                onClick={() => handleNavigate('home')}
                                className="flex w-full cursor-pointer select-none items-center gap-2 rounded-md px-3 pt-[9px] pb-2 text-start leading-tight outline-none transition-all hover:bg-gray-400 hover:bg-opacity-80  focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                            >
                                <i className="fa-solid fa-chart-line"></i>
                                <p className="block font-sans text-sm font-normal leading-normal text-inherit antialiased" >
                                    Dashboard
                                </p>
                            </button>
                            <button
                                tabIndex="-1"
                                role="menuitem"
                                className="flex w-full cursor-pointer select-none items-center gap-2 rounded-md px-3 pt-[9px] pb-2 text-start leading-tight outline-none transition-all hover:bg-gray-400 hover:bg-opacity-80   focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                    className="h-4 w-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                                    ></path>
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                    ></path>
                                </svg>
                                <Link to='/provider/settings' className="block font-sans text-sm font-normal leading-normal text-inherit antialiased no-underline">
                                    Edit Profile
                                </Link>
                            </button>
                            <button
                                tabIndex="-1"
                                role="menuitem"
                                onClick={() => { navigate('/login') }}
                                className="flex w-full cursor-pointer select-none items-center gap-2 rounded-md px-3 pt-[9px] pb-2 text-start leading-tight outline-none transition-all hover:bg-gray-400 hover:bg-opacity-80   focus:bg-blue-gray-50 focus:bg-opacity-80 focus:text-blue-gray-900 active:bg-blue-gray-50 active:bg-opacity-80 active:text-blue-gray-900"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    aria-hidden="true"
                                    className="h-4 w-4"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
                                    ></path>
                                </svg>
                                <p className="block font-sans text-sm font-normal leading-normal text-inherit antialiased">
                                    Logout
                                </p>
                            </button>
                        </ul>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default ProvdierNav;
