import React, { useEffect, useState } from 'react';
import { Button, Collapse, ConfigProvider, message, Modal, Steps, Tooltip } from 'antd';
import { FileOutlined, PlayCircleOutlined, QuestionCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import Quiz from '../../../Components/Quiz/Quiz';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Player } from 'video-react';
import "../../../../node_modules/video-react/dist/video-react.css"; // import css

import './WatchCourse.css'
export default function WatchCourse({ baseUrl }) {
    const [chapterCurrent, setchapterCurrent] = useState(0);
    const [currentStepInChapters, setCurrentStepInChapters] = useState({});
    const [courseDetails, setCourseDetails] = useState('');
    const [courseChapters, setCourseChapters] = useState([]);
    const [videoSrc, setvideoSrc] = useState();
    const [quizID, setquizID] = useState();
    const [isQuiz, setisQuiz] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedResources, setSelectedResources] = useState([]);
    const headers = {
        'Authorization': `Bearer ${localStorage.getItem("userToken")}`
    }
    const navigate = useNavigate();
    const { id } = useParams();

    const customTheme = {
        token: {
            colorBgContainer: '', // Set the background color
        },
    };

    useEffect(() => {
        getCourseDetails();
        getCourseChapters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getCourseDetails() {
        try {
            const res = await axios.get(`${baseUrl}/api/Course/GetCourseById?Id=${id}`,{headers});
            setCourseDetails(res.data);
        } catch (err) {
            
        }
    }

    async function getCourseChapters() {
        try {
            const res = await axios.get(`${baseUrl}/api/Content/GetCourseData?CourseId=${id}`,{headers});
            setCourseChapters(res?.data?.chapters);
        } catch (err) {
            if(err?.response?.status === 403){
                message.error('You do not have permission to access this course')
                navigate('/home', { replace: true });
            } 
            
        }
    }

    function changeVideo(videoLink, chapterIndex, stepIndex) {
        setisQuiz(false);
        setvideoSrc(null);
        setTimeout(() => {
            setvideoSrc(videoLink);
        }, 200);

        setCurrentStepInChapters({
            ...currentStepInChapters,
            [chapterIndex]: stepIndex,
        });
    }

    function showQuiz(quizId, chapterIndex) {
        setvideoSrc()
        setisQuiz(false);
        setquizID()
        setTimeout(() => {
            setisQuiz(true);
            setquizID(quizId)
        }, 200);;

        setCurrentStepInChapters({
            ...currentStepInChapters,
            [chapterIndex]: 'quiz',
        });
    }

    function showResourcesModal(resources, e) {
        e.stopPropagation()
        setSelectedResources(resources);
        setIsModalVisible(true);
    }

    function handleModalClose() {
        setIsModalVisible(false);
        setSelectedResources([]);
    }

    return (
        <>
          <div className=''>
              {/* <div className='flex justify-between items-center'>
                <h1 className='text-2xl text-[--yellow]  w-fit rounded-md'>{courseDetails?.name}</h1>
                <span className='text-black'>({courseChapters?.length} Chapters)</span>
            </div> */}
            <div className=' sm:h-60 sm:p-0 pt-5 relative sm:bg-black sm:bg-opacity-25  flex justify-center items-center' >
                <h3 className='sm:text-5xl text-3xl font-bold text-[--yellow] '>{courseDetails?.name} </h3>
                <img src={`${baseUrl}/api/Storage/GetImageById?id=${courseDetails?.cover}`}
                    className='h-full -z-10 absolute  w-full object-cover rounded-md sm:inline-block hidden  ' alt="" />
            </div>
            <div className='flex md:flex-row flex-col-reverse px-2  gap-3  container items-start  mx-auto sm:my-5'>

                {/* Chapters and Steps */}
                <div className='flex-1 mt-2'>

                    <ConfigProvider theme={customTheme}>
                        <Collapse

                            onChange={(e) => setchapterCurrent(e[0])}
                            activeKey={chapterCurrent}
                            size="small"
                            accordion
                            className='  space-y-3 border-none rounded-none '
                        >
                            {courseChapters?.map((chapter, chapterIndex) => {
                                return <Collapse.Panel className='bg-[--offwhite-color] ' key={chapterIndex} header={<span className='text-[#405d72]  '>{chapter.chapterName}</span>} extra={<Tooltip title="Resources" >
                                    <button onClick={(e) => showResourcesModal(chapter?.pdfLinks, e)} className='flex justify-center items-center size-7 p-0 m-0 overflow-hidden bg-blue-500 text-white rounded-full hover:bg-blue-400'>
                                        <FileOutlined className='m-0 p-0 w w-4' />
                                    </button>
                                </Tooltip>}>


                                    <Steps
                                        className=' max-h-60 overflow-auto cursor-pointer '
                                        direction="vertical"

                                        current={currentStepInChapters[chapterIndex] ?? -1}
                                    >
                                        {chapter?.videoLinks?.map((video, stepIndex) => (
                                            <Steps.Step
                                                key={stepIndex}
                                                title={
                                                    <span className={`${videoSrc === video.link ? 'text-blue-400' : 'text-[#405d72]'}`}>
                                                        {video?.name}
                                                    </span>
                                                }
                                                icon={
                                                    <PlayCircleOutlined
                                                        className={videoSrc === video.link ? 'text-blue-400' : 'text-[#405d72]'}
                                                    />
                                                }
                                                onClick={() =>
                                                    changeVideo(video.link, chapterIndex, stepIndex)
                                                }
                                            />
                                        ))}
                                        {chapter.quizId && (
                                            <Steps.Step
                                                key="quiz"
                                                className='cursor-pointer'
                                                title={<span className={currentStepInChapters[chapterIndex] === 'quiz' && quizID === chapter.quizId ? 'text-blue-400' : 'text-[#405d72]'}>Quiz</span>}
                                                icon={
                                                    <QuestionCircleOutlined
                                                        className={currentStepInChapters[chapterIndex] === 'quiz' && quizID === chapter.quizId ? 'text-blue-400' : 'text-[#405d72]'}
                                                    />
                                                }
                                                onClick={() => showQuiz(chapter.quizId, chapterIndex)}
                                            />
                                        )}
                                    </Steps>

                                </Collapse.Panel>
                            })}
                        </Collapse>
                    </ConfigProvider>
                </div>
                {/* Video or Quiz display */}
                <div className='md:flex-1 flex-12 mx-auto w-full md:p-0  '>
                    {isQuiz ? (
                        <Quiz id={quizID} baseUrl={baseUrl} />
                    ) : videoSrc ? (
                                <div className='md:m-0 my-10 flex justify-center items-center    '>

                                <Player
                                    className='top-0 '
                                    
                                    autoPlay
                                    src={videoSrc}
                                />
                                </div>
                    ) : (
                        <div className='md:h-[50vh] w-full mt-3'>
                            <img
                                src={`${baseUrl}/api/Storage/GetImageById?id=${courseDetails?.cover}`}
                                alt=""
                                className='w-full h-full object-cover'
                            />
                        </div>
                    )}
                </div>


            </div>

            {/* Resources Modal */}
            <Modal
                title="Resources"
                visible={isModalVisible}
                onCancel={handleModalClose}
                footer={null}
            >
                {selectedResources?.length ? (
                    <ul className="space-y-4">
                        {selectedResources.map((resource, index) => (
                            <li key={index} className="flex justify-between items-center">
                                <div className="flex items-center space-x-3">
                                    <FileOutlined className="text-lg" />
                                    <span>{resource.name}</span>
                                </div>
                                <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                    href={`${baseUrl}/api/Storage/GetPDFById?Id=${resource.link}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Download
                                </Button>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No resources available for this chapter.</p>
                )}
            </Modal>
          </div>
        </>
    );
}
