import React from 'react';

const Option = ({ op, index, questionIndex, questionId, handleAnswerChange, submitted, userAnswer, correctAnswer }) => {
    const optionId = `option-${questionIndex}-${index}`;
    const groupName = `options-${questionId}`;

    // Base styles for options
    let optionStyles = 'inline-flex items-center justify-between w-full p-5 bg-gray-900 bg-opacity-70 border-2 rounded-lg cursor-pointer group border-neutral-200/70 text-white peer-checked:bg-blue-500 peer-checked:border-blue-500';

    // Adjust styles for submitted state
    if (submitted) {
        if (index === correctAnswer) {
            optionStyles += ' bg-green-200/50 border-green-400'; // Highlight correct answer after submission
        } else if (parseInt(userAnswer) === index) {
            optionStyles += ' bg-red-200/50 border-red-400'; // Highlight incorrect selected answer after submission
        }
    } else {
        optionStyles += ' hover:border-neutral-300'; // Add hover effect for unselected options
    }

    return (
        <div className="relative">
            <input
                type="radio"
                name={groupName}
                id={optionId}
                value={index}
                className="hidden peer"
                checked={parseInt(userAnswer) === index} // Ensure the selected option is checked
                onChange={() => handleAnswerChange(questionId, String(index))} // Convert index to string
                disabled={submitted} // Disable input after submission
            />
            <label htmlFor={optionId} className={` ${submitted ? index === correctAnswer ? 'peer-checked:bg-green-200/50  peer-checked:border-green-400 ' : '  peer-checked:bg-red-200/50  peer-checked:border-red-400' : 'peer-checked:bg-blue-200/50  peer-checked:border-blue-400' } ${optionStyles}`}>
                <div className="flex items-center space-x-5">
                    <h3>{index + 1})</h3>
                    <div className="flex flex-col justify-start">
                        <div className="w-full text-lg font-semibold">{op}</div>
                    </div>
                </div>
            </label>
        </div>
    );
};




export default Option;
