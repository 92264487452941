import React, { useEffect, useState } from 'react'
import CreateCourseModal from '../../../Components/CourseModals/CreateCourseModal';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditCourseModal from '../../../Components/CourseModals/EditCourseModal';
import { Tooltip } from 'antd';

export default function Dashboard({ baseUrl }) {
    const [courses, setCourses] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    const navigate = useNavigate();
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('providerToken')}`,
    }
    const showModal = () => {
        setIsModalVisible(true);
    };
    const showEditModal = (course) => {
        setSelectedCourse(course);
        setIsEditModalVisible(true);
    };

    const handleEditClose = () => {
        setIsEditModalVisible(false);
        setSelectedCourse(null);
    };

    const deleteCourse = async (courseId) => {
        await axios.delete(`${baseUrl}/api/Course/DeleteCourse?Id=${courseId}`, { headers })
            .then((res) => {
                setCourses(courses.filter(course => course.id !== courseId));

            })
            .catch((error) => {
                

            })


    };
    const handleClose = () => {
        setIsModalVisible(false);
    };
    useEffect(() => {
        fetchCourses();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchCourses = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/Provider/GetMyCourses`, { headers });
            const data = await response.json();
            setCourses(data);

        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    return (
        <div>
            <main className="p-6 sm:p-8 space-y-6">
                <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row justify-between">
                    <div className="mr-6">
                        <h1 className="text-4xl font-semibold mb-2">Welcome in ,Bosla</h1>
                        {/* <h2 className="text-gray-600 ml-0.5">courses DashBoard</h2> */}
                    </div>
                    <div className="flex flex-wrap items-start justify-end -mb-3">
                        {/* content    */}
                    </div>
                </div>

                <section className="grid grid-cols-2 xl:grid-cols-4 gap-6 ">
                    <div className="flex items-center p-8  bg-[--bg-secoundry-color]  shadow-lg rounded-lg sm:flex-row flex-col justify-start gap-3 ">
                        <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16  text-teal-600 bg-teal-100  rounded-full sm:mr-6">
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                            </svg>
                        </div>
                        <div className=' sm:text-start text-center'>
                            <span className="block text-2xl font-bold">62</span>
                            <span className="block   ">Students</span>
                        </div>
                    </div>
                    <div className="flex items-center p-8  bg-[--bg-secoundry-color]  shadow-lg rounded-lg sm:flex-row flex-col justify-start gap-3 ">
                        <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-green-600 bg-green-200 rounded-full sm:mr-6">
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                            </svg>
                        </div>
                        <div className=' sm:text-start text-center'>
                            <span className="block text-2xl font-bold">6.8</span>
                            <span className="block   ">Average Rates</span>
                        </div>
                    </div>

                    <div className="flex items-center p-8  bg-[--bg-secoundry-color]  shadow-lg rounded-lg sm:flex-row flex-col justify-start gap-3 ">
                        <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-blue-600 bg-blue-100 rounded-full sm:mr-6">
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                            </svg>
                        </div>
                        <div className=' sm:text-start text-center'>
                            <span className="block text-2xl font-bold">12</span>
                            <span className="block   ">Published Course</span>
                        </div>
                    </div>
                    <div className="flex items-center p-8  bg-[--bg-secoundry-color]  shadow-lg rounded-lg sm:flex-row flex-col justify-start gap-3 ">
                        <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-yellow-600 bg-yellow-100 rounded-full sm:mr-6">
                            <svg aria-hidden="true" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                                <path fill="#fff" d="M12 14l9-5-9-5-9 5 9 5z" />
                                <path fill="#fff" d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                            </svg>
                        </div>
                        <div className=' sm:text-start text-center'>
                            <span className="block text-2xl font-bold">25</span>
                            <span className="block   ">graduated student</span>
                        </div>
                    </div>

                </section>

                <section className="grid grid-cols-1 lg:grid-cols-3  xl:grid-flow-col gap-6">
                    <div className="flex flex-col md:col-span-2 md:row-span-2  bg-[--bg-secoundry-color]  shadow-lg rounded-lg">
                        <div className='border-b border-gray-100 flex justify-between w-full items-center bgda px-5 py-3'>
                            <div className=" font-semibold ">Courses</div>
                            <button className="inline-flex items-center px-3 py-2 text-white bg-[--btn-color] hover:bg-[--dark-hover]  rounded-md " onClick={showModal}>
                                <i className='fa fa-plus me-1 '></i>
                                Create Course
                            </button>
                        </div>
                        <div className=" flex-grow">

                            <div className="flex-auto block py-8 pt-6 px-9">
                                <div className="overflow-x-auto">
                                    <table className="w-full my-0 align-middle text-dark border-neutral-200">
                                        <thead className="align-bottom">
                                            <tr className="font-semibold text-[0.95rem] text-secondary-dark pb-3">
                                                <th className="text-start min-w-[175px]">Name</th>
                                                {/* <th className="text-center min-w-[120px]">Enrolls</th> */}
                                                <th className="text-center min-w-[120px] ">Description</th>
                                                <th className="text-center min-w-[120px]">Status</th>
                                                <th className="text-center min-w-[120px]">Category</th>
                                                <th className="text-center min-w-[120px]">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {courses?.map((course, index) => (
                                                <tr key={index} className="border-b border-dashed last:border-b-0 hover:bg-gray-500 rounded-xl cursor-pointer" onClick={() => navigate(`/provider/course/${course.id}`)}>
                                                    <td className="p-3 pl-0">
                                                        <div className="flex items-center">
                                                            <div className="relative inline-block shrink-0 rounded-full me-3">
                                                                <img src={`${baseUrl}/api/Storage/GetImageById?id=${course.cover}` || "default-image.jpg"} className="w-[40px] h-[40px] inline-block shrink-0 rounded-full object-cover" alt={course.name} />
                                                            </div>
                                                            <div className="flex flex-col justify-start">
                                                                <p className="mb-1 font-semibold transition-colors duration-200 ease-in-out text-lg/normal text-secondary-inverse hover:text-primary">
                                                                    {course.name}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/* <td className="p-3 pr-0 text-center font-semibold">
                                                        {course.enrolls}
                                                    </td> */}
                                                    <td className="p-3 pr-0 text-center">
                                                        <Tooltip title={course.descriptionText}>
                                                            <span className="font-semibold text-light-inverse text-sm">{course?.descriptionText?.slice(0, 75)}..</span>
                                                        </Tooltip>
                                                    </td>
                                                    <td className="p-3 text-center">
                                                        {course.isApproved ?
                                                            <span className="text-center align-baseline inline-flex px-3 py-2 mr-auto items-center font-semibold text-[.95rem] leading-none text-blue-600 bg-blue-300 rounded-full">
                                                                Active
                                                            </span>
                                                            :
                                                            <span className="text-center align-baseline inline-flex px-3 py-2 mr-auto items-center font-semibold text-[.95rem] leading-none text-gray-600 bg-gray-300 rounded-full">
                                                                Pending
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="p-3 text-center">
                                                        <span className="font-semibold text-light-inverse text-md/normal">{course.categoryName}</span>
                                                    </td>
                                                    <td className="p-3 text-center space-x-3">
                                                        <i className="fa fa-edit text-blue-500 hover:text-blue-600 cursor-pointer text-lg" onClick={(e) => { e.stopPropagation(); showEditModal(course); }}></i>
                                                        <i className="fa fa-trash text-red-500 hover:text-red-600 cursor-pointer text-lg" onClick={(e) => { e.stopPropagation(); deleteCourse(course.id); }}></i>
                                                    </td>
                                                </tr>

                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row-span-2  bg-[--bg-secoundry-color]  shadow-lg rounded-lg">
                        <div className="flex items-center justify-between px-6 py-5 font-semibold border-b border-gray-100">
                            <span>Rates</span>
                            <button type="button" className="inline-flex justify-center rounded-md px-1 -mr-1  bg-[--bg-secoundry-color]  text-sm leading-5 font-medium    hover:text-gray-200" id="options-menu" aria-haspopup="true" aria-expanded="true">
                                Descending
                                <svg className="-mr-1 ml-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </button>
                        </div>
                        <div className="overflow-y-auto h-[18rem]">
                            <ul className="p-6 space-y-6">
                                <li className="flex items-center">
                                    <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                        <img src="https://randomuser.me/api/portraits/women/82.jpg" alt="Annette " />
                                    </div>
                                    <span className="">Annette Watson</span>
                                    <span className="ml-auto font-semibold">9.3</span>
                                </li>
                                <li className="flex items-center">
                                    <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                        <img src="https://randomuser.me/api/portraits/men/81.jpg" alt="Calvin " />
                                    </div>
                                    <span className="">Calvin Steward</span>
                                    <span className="ml-auto font-semibold">8.9</span>
                                </li>
                                <li className="flex items-center">
                                    <div className="h-10 w-10 mr-3 bg-gray-100 rounded-full overflow-hidden">
                                        <img src="https://randomuser.me/api/portraits/men/80.jpg" alt="Ralph " />
                                    </div>
                                    <span className="">Ralph Richards</span>
                                    <span className="ml-auto font-semibold">8.7</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                </section>

            </main>
            <CreateCourseModal
                visible={isModalVisible}
                onClose={handleClose}
                baseUrl={baseUrl}
                fetchCourses={fetchCourses}
            />
            <EditCourseModal
                visible={isEditModalVisible}
                onClose={handleEditClose}
                baseUrl={baseUrl}
                fetchCourses={fetchCourses}
                courseData={selectedCourse}
            />
        </div>
    )
}
