import React, { useState } from 'react'
import './CourseCard.css'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
export default function CourseCard({ course, baseUrl }) {


    let navigate=useNavigate()

    const [isLiked, setIsLiked] = useState(course?.isLiked)
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    }

    const token = localStorage.getItem('userToken')

    async function likeCourse() {
        await axios.post(`${baseUrl}/api/Course/LikeCourse?CourseId=${course?.id}`, {}, { headers }).then((res) => {
            setIsLiked(prev => !prev)

        }).catch((err) => {

        })
    }


    return (

        <>


            <div class=" bg-black/50 h-fit p-3 rounded-3xl  ">
                <div class="md:h-40 h-32 overflow-hidden relative rounded-xl">
                    <Link to={`/Course/${course?.id}`} state={{ isLikedCourse: course?.isLiked }} >
                        <img src={`${baseUrl}/api/Storage/GetImageById?id=${course?.cover}`} alt="" className='w-full h-full   object-cover hover:scale-110 duration-500 transition-all' />
                    </Link>
                    <div class=" absolute top-2 right-2 flex gap-1 p-1 bg-gray-700 bg-opacity-50  rounded-lg  ">
                        <i className='fa fa-star text-yellow-400'></i>
                        <p className='text-sm'>5.0</p>
                    </div>
                    {isLiked ?
                        <div className={` ${token ? 'absolute top-2 left-2 flex gap-1 p-2 bg-[--main-color]  rounded-full bg-opacity-90  justify-center  items-center' : 'hidden'}`}>

                            <i onClick={likeCourse} className="fa-solid fa-heart cursor-pointer text-red-500"></i>
                        </div> : <div className={` ${token ? 'absolute top-2 left-2 flex gap-1 p-2 bg-[--main-color]  rounded-full bg-opacity-90  justify-center  items-center' : 'hidden'}`}>

                            <i onClick={likeCourse} className="fa-regular fa-heart cursor-pointer"></i>
                        </div>}



                </div>
                <div className=''>
                    <div class=" mt-2   sm:flex  sm:justify-between overflow-hidden text-[--offwhite-color] items-center ">
                        <p class="  sm:text-start text-center text-xl ">{course?.name} </p>
                        <span class="md:text-title sm:inline-block hidden sm:text-lg text-[--yellow]">EGP{course?.price}</span>
                    </div>
                    <p class="md:text-body text-sm my-1 text-[--offwhite-color]  sm:inline-block hidden">
                        {course?.descriptionText?.slice(0, 200)}
                        <span className='md:text-sm text-base cursor-pointer'> ....</span>
                    </p>
                
                </div>
                {course.isOwned?
                    <Link to={`/Course/${course?.id}`} state={{ isLikedCourse: course?.isLiked }} >
                        <button className='bg-green-800 hover:bg-green-700 text-green-200 w-full md:p-2 p-1 my-1 rounded-full md:font-bold text-base sm:m-0 mt-3'>Owned</button>
                    </Link>
               :
                <Link to={`/Course/${course?.id}`} state={{ isLikedCourse: course?.isLiked }} >
                        <button className='bg-[--yellow] hover:bg-orange-300 text-[--main-color] w-full md:p-2 p-1 my-1 rounded-full md:font-bold text-base sm:m-0 '>
                            <span className='sm:inline-block hidden'>Enroll now</span>
                            <span className='sm:hidden'>{course?.price}EGP</span>
                            </button>
                </Link>
                }


            </div>






            {/* <div class=" bg-black/50 h-fit p-3 rounded-3xl ">
                <div class="md:h-40 h-32 overflow-hidden relative rounded-xl">
                    <Link to={`/Course/${course?.id}`} state={{ isLikedCourse: course?.isLiked }} >
                    <img src={`${baseUrl}/api/Storage/GetImageById?id=${course?.cover}`} alt="" className='w-full h-full   object-cover hover:scale-110 duration-500 transition-all' />
                 </Link>
                    <div class=" absolute top-2 right-2 flex gap-1 p-1 bg-gray-700 bg-opacity-50  rounded-lg  ">
                        <i className='fa fa-star text-yellow-400'></i>
                        <p className='text-sm'>4.5</p>
                    </div>
                    {  isLiked ?
                        <div className={` ${token ? 'absolute top-2 left-2 flex gap-1 p-2 bg-[--main-color]  rounded-full bg-opacity-90  justify-center  items-center':'hidden'}`}>

                            <i onClick={likeCourse} className="fa-solid fa-heart cursor-pointer text-red-500"></i>
                        </div> : <div className={` ${token ? 'absolute top-2 left-2 flex gap-1 p-2 bg-[--main-color]  rounded-full bg-opacity-90  justify-center  items-center':'hidden'}`}>

                            <i onClick={likeCourse} className="fa-regular fa-heart cursor-pointer"></i>
                        </div> }



                </div>
                <div className=''>
                    <div class=" mt-2  flex justify-between overflow-hidden text-[--offwhite-color] ">
                        <p class="text-title">{course?.name} </p>
                        <span class="text-title text-[--yellow]">{course?.price}</span>

                    </div>
                        <p class="text-body   ">
                            {course?.descriptionText?.slice(0, 250)}
                            <span className='text-blue-900 text-lg'> ....SEE MORE</span>
                               </p>
                    <div class="card-footer">

                    </div>
                </div>
                <Link to={`/Course/${course?.id}`} state={{ isLikedCourse: course?.isLiked }} >
                    <button className='bg-[--yellow] hover:bg-orange-300 text-[--main-color] w-full p-2 rounded-full font-bold '>Enroll now</button>
                </Link>


            </div> */}
            {/* <Link to={`/Course/${course?.id}`} class="no-underline group bg-[--offwhite-color] h-fit p-3 flex justify-between items-center ">
                <div className='card-body grow '>
                    <p class="text-title text-[--yellow]">{course?.name} </p>
                        <p class="text-[--main-color] ">{course?.descriptionText}  </p>
                    <span class="text-title text-[--main-color] ">{course?.price} EGP</span>
                </div>
                <i className='fa fa-angle-right fa-2xl text-[--yellow] group-hover:opacity-100 opacity-0 transition-all duration-200'></i>
            </Link> */}

        </>

    )
}
