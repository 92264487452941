import React from 'react'

export default function EmptyPage({mssg}) {
    return (
        <div className='w-full  flex justify-center items-center'>
            <div className=' text-center'>

                <i class="fa-solid fa-inbox fa-fa-2xl"></i>
                <h1 className='text-2xl'>{mssg}</h1>
            </div>


        </div>
    )
}
