import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ConfigProvider, FloatButton, Layout, Menu, Drawer, Button } from 'antd';
import { LogoutOutlined, AppstoreOutlined, UserOutlined, CreditCardOutlined, VideoCameraAddOutlined, MenuOutlined } from '@ant-design/icons';

const { Content, Sider } = Layout;

export default function AdminLayout({ role }) {
  let navigate = useNavigate();
  const location = useLocation();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  const menuItems = [
    role === 0 ? { key: 'admins', icon: <UserOutlined className='text-lg' />, label: 'Admins' } :null,
    { key: 'providers', icon: <UserOutlined className='text-lg' />, label: 'Providers' },
    { key: 'categories', icon: <AppstoreOutlined className='text-lg' />, label: 'Categories' },
    { key: 'payments', icon: <CreditCardOutlined className='text-lg' />, label: 'Payment' },
    { key: 'courses', icon: <VideoCameraAddOutlined className='text-lg' />, label: 'Courses' },
    { key: 'login', icon: <LogoutOutlined className='text-lg text-red-500' />, label: 'Log-out', onClick: () => navigate('login') },
  ];

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            siderBg: '#1c2027',
            triggerColor: '#000',
            darkItemSelectedBg: "#000",
            itemActiveBg: "#000",
            itemSelectedBg: "#000",

          },
          Menu: {
            itemSelectedBg: "#2E8A99",
            itemSelectedColor: '#fff',
            colorText: '#ffffff'
          },
          Drawer: {
            colorBgBase: '#1c2027',
            colorTextBase: '#ffffff',
            colorBgContainer: '#1c2027',

          },
        },
      }}
    >
      <Layout className='min-h-screen bg-[--bg-secoundry-color]'>
        {isMobile ? (
          <>
            <Button
              type="primary"
              size='large'
              icon={<MenuOutlined />}
              onClick={() => setDrawerVisible(true)}
              style={{ margin: '16px' }}
            />
            <Drawer
              title="Welcom in Bosla"
              placement="left"
              closable={false}
              onClose={() => setDrawerVisible(false)}
              visible={drawerVisible}
              bodyStyle={{ padding: 0 }}
              headerStyle={{ backgroundColor: '#1c2027', color: '#fff' }}
              drawerStyle={{ backgroundColor: '#1c2027' }}
            >
              <Menu
                className='bg-[#1c2027]'
                mode="inline"
                defaultSelectedKeys={location.pathname.substring(role === 0 ? 12 : 7)}
                onClick={({ key }) => {
                  navigate(key);
                  setDrawerVisible(false);
                }}
                items={menuItems}
              />
            </Drawer>
          </>
        ) : (
          <Sider
            trigger={null}
            breakpoint="md"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
            }}
            onCollapse={(collapsed, type) => {
            }}
          >
            <div className="w-full py-4 flex items-center justify-center">
              <span className='w-20 flex justify-center cursor-pointer rounded-full overflow-hidden'>
                {/* <img src={require("./logo.jpg")} alt="" className='w-full' /> */}
              </span>
            </div>
            <Menu
              className='relative bg-[#1c2027]'
              mode="inline"
                defaultSelectedKeys={location.pathname.substring(role === 0 ? 12 :7)}
              onClick={({ key }) => navigate(key)}
              items={menuItems}
            />
          </Sider>
        )}
        <ConfigProvider
          theme={{
            components: {
              Layout: {
                headerHeight: 'auto',
              },
            },
          }}
        >
          <Layout className='px-5 bg-transparent'>
            <Content
              className='w-full overflow-hidden'
              style={{
                backgroundColor: "#282c34",
                color: 'white'
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </ConfigProvider>
      </Layout>
      <FloatButton.BackTop className='bg-red-500 text-yellow-500' />
    </ConfigProvider>
  );
}
