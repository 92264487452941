import { Button, List, Modal, Form, Input, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Admins({ baseUrl, role }) {
  const [initLoading, setInitLoading] = useState(true);
  const [list, setList] = useState([]);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [addLoading, setAddLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { confirm } = Modal;

  let headers = {
    Authorization: `Bearer ${localStorage.getItem('superToken' )}`,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}/api/User/Admins`, { headers });
      setList(res.data);
      setInitLoading(false);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        navigate('/login');
      }
      setInitLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showAddModal = () => {
    setIsAddModalVisible(true);
    form.resetFields();
  };

  const showEditModal = (item) => {
    setEditItem(item);
    setIsEditModalVisible(true);
    form.setFieldsValue(item);
  };

  const handleAddAdmin = async (values) => {
    
    try {
      setAddLoading(true);
      const res = await axios.post(`${baseUrl}/api/User/RegisterAsAdmin`, values, { headers });
      setIsAddModalVisible(false);
      getData();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      message.error(error.response.data ? error.response.data.errors[0]:'Failed to add admin');
    } finally {
      setAddLoading(false);
    }
  };

  const handleEditAdmin = async (values) => {
    const { userName, email, phoneNumber } = values;
    try {
      setEditLoading(true);
      await axios.put(`${baseUrl}/api/User/EditAdmin`, { id: editItem.id, userName, email, phoneNumber }, { headers });
      setIsEditModalVisible(false);
      getData();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login');
      }
      message.error('Failed to edit admin');
    } finally {
      setEditLoading(false);
    }
  };

  const handleDeleteAdmin = (id) => {
    confirm({
      title: 'Are you sure you want to delete this admin?',
      icon: <ExclamationCircleOutlined />,
      content: 'This action cannot be undone.',
      onOk() {
        axios.delete(`${baseUrl}/api/User/DeleteAdmin?id=${id}`, { headers })
          .then((res) => {
            message.success('Admin deleted successfully');
            getData();
          }).catch((err) => {
            if (err.response && err.response.status === 401) {
              navigate('/login');
            }
            message.error('Failed to delete admin');
          });
      },
      onCancel() {
      },
    });
  };

  return (
    <div className='flex justify-center mt-28'>
      <div className='container'>
        <div className='text-end'>
          <button className='bg-[--btn-color] px-3 py-1 text-lg my-2 hover:bg-[--light-hover] rounded-lg' onClick={showAddModal}>
            <i className='fa fa-add' /> Add admin
          </button>
        </div>
        <List
          className="p-5 rounded-xl bg-[#1c2027]"
          loading={initLoading}
          itemLayout="horizontal"
          dataSource={list}
          renderItem={(item) => (
            <List.Item
              actions={[
                <Button type='link' className='text-[#2E8A99] text-lg' onClick={() => showEditModal(item)}>
                  <i className='fa fa-edit'></i>
                </Button>,
                <Button type='link' className='text-lg' danger onClick={() => handleDeleteAdmin(item.id)}>
                  <i className='fa fa-trash'></i>
                </Button>
              ]}
            >
              <List.Item.Meta
                title={<p className='text-white'>{item.userName}</p>}
                description={<p className='text-white'>{item.email}</p>}
              />
              <p className='text-white'>{item.phoneNumber}</p>

            </List.Item>
          )}
        />
        <Modal
          title="Add Admin"
          visible={isAddModalVisible}
          onCancel={() => setIsAddModalVisible(false)}
          footer={[
            <Button key="back" onClick={() => setIsAddModalVisible(false)}>Cancel</Button>,
            <Button key="submit" type="primary" loading={addLoading} onClick={() => form.submit()}>
              Add
            </Button>,
          ]}
        >
          <Form form={form} onFinish={handleAddAdmin} layout='vertical' requiredMark={false}>
            <Form.Item name="userName" label="Username" rules={[{ required: true, message: 'Please enter username' }]}>
              <Input size='' />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter email' }]}>
              <Input size='' />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true, message: 'Please enter phone number' }]}>
              <Input size='' />
            </Form.Item>
            <Form.Item name="password" label="password" rules={[{ required: true, message: 'Please enter password' }]}>
              <Input size='' />
            </Form.Item>
              <p className='text-gray-400 mt-2'>Password must contain 8 character include lowercase, uppercase , numper and spechial charcter </p>
          </Form>
        </Modal>
        <Modal
          title="Edit Admin"
          visible={isEditModalVisible}
          onCancel={() => setIsEditModalVisible(false)}
          footer={[
            <Button key="back" onClick={() => setIsEditModalVisible(false)}>Cancel</Button>,
            <Button key="submit" type="primary" loading={editLoading} onClick={() => form.submit()}>
              Save
            </Button>,
          ]}
        >
          <Form form={form} onFinish={handleEditAdmin}>
            <Form.Item name="userName" label="Username" rules={[{ required: true, message: 'Please enter username' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter email' }]}>
              <Input />
            </Form.Item>
            <Form.Item name="phoneNumber" label="Phone Number" rules={[{ required: true, message: 'Please enter phone number' }]}>
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </div>
  );
}
