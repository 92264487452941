import React, { useState } from 'react'
import img1 from '../../images/logo.svg'
import axios from 'axios'
import { notification } from 'antd';
import { motion } from 'framer-motion'

export default function LoginPopUp({ setPopUp, baseUrl }) {
    const [api, contextHolder] = notification.useNotification();
    const [authMethoud, setauthMethoud] = useState(2);
    const [email, setemail] = useState();
    const Error = (message) => {
        api.error({
            type: 'error',
            message: `${message}`,
            placement: 'topRight',
        });
    };
    const Succsess = (message) => {
        api.success({
            message: `${message}`,
            placement: 'topRight',
        });
    };
    const [flag, setFlag] = useState(false)
    const [userRegister, setUserRegister] = useState({
        username: "",
        email: "",
        password: ""
    })
    const [userLogin, setUserLogin] = useState({
        email: "",
        password: ""
    })


    //Register
    const handleRegister = async (e) => {
        e.preventDefault()

        await axios.post(`${baseUrl}/api/User/RegisterAsUser`, userRegister).then((res) => {
            Succsess('Account Created Succsessfully')
            localStorage.setItem('userToken', res.data.token)
            window.location.reload()

            setFlag(false)
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                Error(error?.response?.data.errors[0] ? error?.response?.data.errors[0] : 'Email already in use or invalid data');
            } else {
                Error('Sign up failed');
            }

        })
    }

    const handleRegisterForm = (e) => {
        let register = { ...userRegister }
        register[e.target.name] = e.target.value
        setUserRegister(register)


    }

    //Login

    const handleLogin = async (e) => {
        e.preventDefault()

        await axios.post(`${baseUrl}/api/User/Login`, userLogin).then((res) => {
            localStorage.setItem('userToken', res.data.token)
            Succsess('Login Succsessfully')
            window.location.reload()
        }).catch((error) => {
            if (error.response && error.response.status === 400) {
                Error(error?.response?.data.errors[0] ? error?.response?.data.errors[0] : 'Email already in use or invalid data');
            } else {
                Error('Login failed');
            }

        })
    }
    const handleguest = async (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        await axios.post(`${baseUrl}/api/User/SignAsGuest`, data).then((res) => {
            localStorage.setItem('userToken', res.data.token)
            Succsess('Login Succsessfully')
            window.location.reload()
        }).catch((error) => {
            
            if (error.response && error.response.status === 400) {
                Error(error?.response?.data.errors[0] ? error?.response?.data.errors[0] : 'Email already in use or invalid data');
            } else {
                Error('Login failed');
            }

        })
    }

    const handleLoginForm = (e) => {
        let login = { ...userLogin }
        login[e.target.name] = e.target.value
        setUserLogin(login)



    }



    return (

        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50'
        >
            {contextHolder}
            {!authMethoud ?
                <div className={` absolute     w-full h-full flex justify-center items-center`}>
                    <div className='bg-[--main-color] flex flex-col gap-3 px-5 py-5  md:w-1/3 sm:w-1/2 shadow-lg rounded-xl' >
                        <i onClick={() => {
                            setPopUp(false)
                        }} className='fa-solid fa-x cursor-pointer text-gray-400 hover:text-gray-300 w-fit' ></i>
                        <div className='flex  items-center  w-full sm:justify-between justify-center'>
                            <h1 className='font-bold sm:inline hidden'>Welcome to Bosla</h1>
                            <img src={img1} className='w-36' alt="" />
                        </div>
                        <p className='text-center'>you don't have an account ? choose an auth methoud</p>
                        <div onClick={() => { setauthMethoud(1) }} className='bg-gray-200 text-xl flex items-center justify-center gap-2 bg-opacity-20 p-4 rounded-full placeholder:text-center border border-gray-700 hover:text-gray-700 text-white text-center w-2/3 mx-auto  cursor-pointer hover:bg-opacity-40  hover:shadow-lg '  >
                            <i className='text-xl fa-regular fa-user-circle'></i> Continue as guest
                        </div>
                        <div onClick={() => { setauthMethoud(2) }} className='bg-gray-200 text-xl flex items-center justify-center gap-2 bg-opacity-20 p-4 rounded-full placeholder:text-center border border-gray-700 hover:text-gray-700 text-white text-center w-2/3 mx-auto  cursor-pointer hover:bg-opacity-40  hover:shadow-lg '  >
                            <i className='text-xl fa fa-user-check'></i> Create Account
                        </div>



                    </div>
                </div>
                :
                <>
                    {authMethoud === 1 ?
                        <div className={` ${flag ? 'hidden ' : 'absolute '}    w-full h-full flex justify-center items-center`}>
                            <form onSubmit={handleguest} className='bg-[--main-color] flex flex-col gap-3 px-5 py-5  md:w-1/3 sm:w-1/2 shadow-lg rounded-xl' >
                                <i onClick={() => {
                                    setPopUp(false)
                                }} className='fa-solid fa-x cursor-pointer text-gray-400 hover:text-gray-300 w-fit' ></i>
                                <div className='flex  items-center  w-full sm:justify-between justify-center'>
                                    <h1 className='font-bold sm:inline hidden'>Welcome to Bosla</h1>
                                    <img src={img1} className='w-36' alt="" />
                                </div>
                                <p className='text-center'>please enter your email to identify your guest account</p>
                                <input type="email" placeholder='Email' value={email} className='bg-[--offwhite-color] p-4 rounded-full placeholder:text-center text-black' id='email' name='email' onChange={(e) => setemail(e.target.value)} />




                                <button type="submit" className='w-full sm:w-auto text-2xl sm:text-5xl text-[--yellow] hover:text-yellow-400 anton'>
                                    Continue
                                </button>
                                <button onClick={() => { setauthMethoud() }} className='w-full sm:w-auto   text-gray-400 hover:text-gray-300 anton '>
                                    Back
                                </button>


                            </form>
                        </div> :
                        <>
                            <div className={` ${flag ? 'hidden ' : 'absolute '}    w-full h-full flex justify-center items-center`}>
                                <form onSubmit={handleLogin} className='bg-[--main-color] flex flex-col gap-3 px-5 py-5  md:w-1/3 sm:w-1/2 shadow-lg rounded-xl' >
                                    <i onClick={() => {
                                        setPopUp(false)
                                    }} className='fa-solid fa-x cursor-pointer text-gray-400 hover:text-gray-300 w-fit' ></i>
                                    <div className='flex  items-center  w-full sm:justify-between justify-center'>
                                        <h1 className='font-bold sm:inline hidden'>Welcome Back</h1>
                                        <img src={img1} className='w-36' alt="" />
                                    </div>

                                    <input type="text" placeholder='Name' className='bg-[--offwhite-color] p-4 rounded-full placeholder:text-center text-black' id='email' name='email' onChange={handleLoginForm} />


                                    <input type="password" placeholder='Password' className='bg-[--offwhite-color] p-4 rounded-full placeholder:text-center text-black' id='password' name='password' onChange={handleLoginForm} />


                                    <button type="submit" className='w-full sm:w-auto text-2xl sm:text-5xl text-[--yellow] hover:text-yellow-400 anton'>
                                        Sign in
                                    </button>

                                    <p>Don't have an account? <span className='cursor-pointer text-orange-300 hover:text-orange-400' onClick={() => {
                                        setFlag(true)
                                    }}> Register Now! </span></p>
                                </form>
                            </div>

                            <div className={` ${flag ? 'absolute' : 'hidden'}    w-full h-full flex justify-center items-center`}>
                                <form onSubmit={handleRegister} className='bg-[--main-color] flex flex-col gap-3 px-5 py-5  md:w-1/3 sm:w-1/2 shadow-lg rounded-xl' >
                                    <i onClick={() => {
                                        setPopUp(false)
                                    }} className='fa-solid fa-x cursor-pointer' ></i>
                                    <div className='flex sm:justify-between justify-center items-center gap-3'>

                                        <h1 className='text-2xl sm:inline hidden'>Create Account </h1>
                                        <img src={img1} className='w-36' alt="" />
                                    </div>

                                    <input type="text" onChange={handleRegisterForm} placeholder='User Name' id='username' name='username' className='bg-[--offwhite-color] p-4 rounded-full placeholder:text-center text-black' />


                                    <input type="text" onChange={handleRegisterForm} placeholder='Email' id='email' name='email' className='bg-[--offwhite-color] p-4 rounded-full placeholder:text-center text-black' />

                                    <input type="password" onChange={handleRegisterForm} placeholder='Password' id='password' name='password' className='bg-[--offwhite-color] p-4 rounded-full placeholder:text-center text-black' />





                                    <button type="" className='w-full sm:w-auto text-2xl sm:text-5xl text-[--yellow] hover:text-yellow-400 anton'>
                                        Sign Up
                                    </button>
                                    <p >Already have an account? <span className='cursor-pointer text-orange-300 hover:text-orange-400' onClick={() => {
                                        setFlag(false)
                                    }}> Login</span></p>
                                </form>
                            </div>
                        </>
                    }
                </>
            }
        </motion.div>

    )
}
