import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRoute(props) {
    if (props.role ===0 &&!localStorage.getItem('superToken'))
        return <Navigate to='/login' />
   
    if (props.role === 1 && !localStorage.getItem('adminToken'))
        return <Navigate to='/login' />
   
    if (props.role === 2 &&!localStorage.getItem('providerToken'))
        return <Navigate to='/login' />
    if (props.role === 3 &&!localStorage.getItem('userToken'))
        return <Navigate to='/login' />
    else
        return props.children
}
