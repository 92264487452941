import React from 'react'
import logo from './../../../images/logo.svg'
import { useNavigate } from 'react-router-dom';
export default function About() {
    const navigate = useNavigate();
    return <>
        <section className=' pt-24 flex justify-center w-full  sm:px-0 px-3' id='who-we-are'>
            <div className='container flex-row '>
                <div className=' flex flex-col-reverse justify-between items-center lg:flex-row lg:gap-0 '>
                    <h1 className='   font-bold md:text-6xl text-4xl text-center mb-5'>Know more About Us</h1>
                    <img src={logo} className='lg:block hidden cursor-pointer' alt="" onClick={()=>{navigate('/')}} />
                </div>
                <div className='flex flex-col space-y-5  w-full'>
                    <label className='flex items-end space-x-0 '>
                        <input className="peer/showLabel absolute scale-0 wf" type="checkbox" />
                        <span className="block max-h-14  overflow-hidden border-b-4 border-[--yellow] w-full transition-all duration-300 peer-checked/showLabel:max-h-52">
                            <h3 className="flex h-14 cursor-pointer items-center font-bold text-3xl">
                                Our vision?
                            </h3>

                            <p className="mb-2">
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive!
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive!
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive!
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive!
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive!
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive! 😎
                            </p>
                        </span>
                        <i className="fa fa-angle-down ml-2  text-3xl text-[--yellow] border-b-4 border-[--yellow] peer-checked/showLabel:rotate-180 peer-checked/showLabel:border-t-4 peer-checked/showLabel:border-b-0" />

                    </label>


                    <label className='flex items-end space-x-0 '>
                        <input class="peer/showLabel absolute scale-0 " type="checkbox" />
                        <span class="block max-h-14  overflow-hidden  border-b-4 border-[--yellow]  w-full transition-all duration-300 peer-checked/showLabel:max-h-52">
                            <h3 class="flex h-14 cursor-pointer items-center font-bold text-3xl">Our Mission?</h3>
                            <p class="mb-2">You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive! 😎
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive! 😎
                            </p>
                        </span>
                        <i className="fa fa-angle-down ml-2  text-3xl text-[--yellow] border-b-4 border-[--yellow] peer-checked/showLabel:rotate-180 peer-checked/showLabel:border-t-4 peer-checked/showLabel:border-b-0" />

                    </label>
                    <label className='flex items-end space-x-0 '>
                        <input class="peer/showLabel absolute scale-0 " type="checkbox" />
                        <span class="block max-h-14  overflow-hidden  border-b-4 border-[--yellow]  w-full transition-all duration-300 peer-checked/showLabel:max-h-52">
                            <h3 class="flex h-14 cursor-pointer items-center font-bold text-3xl">Who do we help?</h3>
                            <p class="mb-2">You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive! 😎
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive! 😎
                            </p>
                        </span>
                        <i className="fa fa-angle-down ml-2  text-3xl text-[--yellow] border-b-4 border-[--yellow] peer-checked/showLabel:rotate-180 peer-checked/showLabel:border-t-4 peer-checked/showLabel:border-b-0" />

                    </label>

                    <label className='flex items-end space-x-0 '>
                        <input class="peer/showLabel absolute scale-0 " type="checkbox" />
                        <span class="block max-h-14  overflow-hidden  border-b-4 border-[--yellow]  w-full transition-all duration-300 peer-checked/showLabel:max-h-52">
                            <h3 class="flex h-14 cursor-pointer items-center font-bold text-3xl">Ready to get started?</h3>
                            <p class="mb-2">You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive! 😎
                                You've crafted a sleek collapsible panel using Tailwind CSS without the need for JavaScript. Impressive! 😎
                            </p>
                        </span>
                        <i className="fa fa-angle-down ml-2  text-3xl text-[--yellow] border-b-4 border-[--yellow] peer-checked/showLabel:rotate-180 peer-checked/showLabel:border-t-4 peer-checked/showLabel:border-b-0" />

                    </label>

                </div>
            </div>

        </section>
    

    </>
}
