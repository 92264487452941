import React from 'react'
import './toggle.css'
export default function RadioToggle({ setRegistrationType }) {
    return (
        <div class="containe  ">
            <div class="tabs justify-center">
                <input type="radio" id="radio-1" value={'user'} name="tabs" checked onChange={(e)=>{setRegistrationType(e.target.value)}}/>
                <label class="tab me-6" for="radio-1"><i className='fa fa-user me-2 fa-lg'></i> User</label>
                <input type="radio" id="radio-2" value={'provider'} name="tabs" onChange={(e)=>{setRegistrationType(e.target.value)}} />
                <label class="tab" for="radio-2"><i className="fa-solid fa-user-tie me-2 fa-lg"></i>Provider</label>
                <span class="glider"></span>
            </div>
        </div>
    )
}
