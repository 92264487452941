import React, { useEffect, useState } from 'react'
import axios from 'axios'
import EmptyPage from './../../../Components/EmptyPage/EmptyPage';
import LoadingScreen from './../../../Components/LoadingScreen/LoadingScreen';
import { Image } from 'antd';
import { useNavigate } from 'react-router-dom';
export default function ProfileUser({ baseUrl }) {
    const [likedCourses, setLikedCourses] = useState([])
    const [myCourses, setmyCourses] = useState([])
    const [userData, setUserData] = useState('')
    const [err, setErr] = useState(false)
    const [uploading, setuploading] = useState(false);
    const navigate = useNavigate()
    const headers = {
        'Authorization': `Bearer ${localStorage.getItem("userToken")}`
    }
    const GetLikedCourses = async () => {
        await axios.get(`${baseUrl}/api/Course/GetUserLikedCourses`, { headers }).then((res) => {
            setLikedCourses(res.data.likedCourses)
            setErr(false)
        }).catch((err) => {
            
            setErr(true)

        })
    }
    const GetmyCourses = async () => {
        await axios.get(`${baseUrl}/api/Course/MyPurchasedCourses`, { headers }).then((res) => {
            setmyCourses(res.data.purchasedCourses)
            setErr(false)
        }).catch((err) => {
            
            setErr(true)

        })
    }
    const GetMyProfile = async () => {
        setuploading(true)
        await axios.get(`${baseUrl}/api/Provider/GetMyProfile`, { headers }).then((res) => {
            setUserData(res.data)
            localStorage.setItem('userPhoto', res?.data?.imageId)

        }).catch((err) => {
            

        })
            .finally(() => {
                setuploading(false)
            })
    }



    async function uploadImage(e) {
        setuploading(true)
        const formData = new FormData()
        formData.append('file', e.target.files[0])
        await axios.post(`${baseUrl}/api/Storage/UploadImage`, formData, { headers }).then((res) => {

            setUserData({ ...userData, imageId: res.data.id })
            changePhoto(res.data.id)
            localStorage.setItem('userPhoto', res.data.id)
            window.location.reload()
        }).catch((err) => {
            
        })
            .finally(() => {
                setuploading(false)
            })
    }
    async function changePhoto(imageId) {
        await axios.post(`${baseUrl}/api/User/UploadImage`, { imageId }, { headers }).then((res) => {

            


        }).catch((err) => {
            

        })
    }



    useEffect(() => {
        GetLikedCourses()
        GetMyProfile()
        GetmyCourses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return <>
        {uploading ? <LoadingScreen fullscreen /> : ''}

        <div className="h-full  bg-[--main-color]  my-10 container mx-auto">
            <div className=" grid grid-cols-12 ">
                <div className='md:col-span-3 col-span-12  flex flex-col justify-center md:items-end items-center  '>
                    <div className='size-40  rounded-full  relative'>
                    <Image   src={userData === undefined || userData?.imageId === null ? 'https://th.bing.com/th/id/OIP.3DtAyOwRrErzTWGNKjf3RwHaHa?rs=1&pid=ImgDetMain' : `${baseUrl}/api/Storage/GetImageById?id=${userData?.imageId}`} alt='Profile' className=" border-4 border-white rounded-full overflow-hidden object-cover" />

                    <label className='cursor-pointer absolute bottom-0 right-5 bg-blue-600 size-8 flex justify-center items-center rounded-full' htmlFor="imageId">
                        <i class="fa-regular fa-pen-to-square mx-2"></i>
                    </label>
                    </div>

                    <input
                        id="imageId"
                        name="imageId"
                        type="file"
                        className=' absolute w-0 '
                        onChange={uploadImage}
                    />
                </div>
                <div className='md:col-span-3 col-span-12  flex flex-col justify-center md:items-start items-center mx-5 '>
                    <h1>{userData?.username}</h1>
                    <h1 className='text-[--yellow]'>{userData?.email}</h1>

                </div>


            </div >



            <div className="flex-1     rounded-lg   mt-4 px-8">
                <h4 className="sm:text-4xl text-3xl  text-[--offwhite-color] font-bold">My Courses </h4>
                <div class=" w-full flex justify-center items-center py-10">
                    <div class="md:px-4 grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-2 gap-5  md:space-y-0">

                        {myCourses?.map((course) => {
                            return <div className='cursor-pointer hover:scale-105 transition-all   duration-300' onClick={()=>{navigate(`/Course/${course.id}`)}}>
                                <img src={`${baseUrl}/api/Storage/GetImageById?id=${course.cover}`} className="w-full lg:size-60 md:size-52 size-32 object-cover rounded-lg" alt="" />
                                <h1 className='text-center text-[--yellow] sm:text-[30px] font-bold'>{course.name}</h1>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className="flex-1     rounded-lg    px-8">
                <h4 className="sm:text-4xl text-3xl  text-[--offwhite-color] font-bold">My WishList </h4>
                <div class=" w-full flex justify-center items-center py-10">

                    {err ? <EmptyPage mssg={'no courses yet'} /> : <div class="md:px-4 grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5  md:space-y-0">

                        {likedCourses?.map((course) => {
                            return <div>
                                <img src={`${baseUrl}/api/Storage/GetImageById?id=${course.cover}`} className="w-full lg:size-60 md:size-52 size-32 object-cover  rounded-lg" alt="" />
                                <h1 className='text-center text-[--yellow] text-[30px] font-bold'>{course.name}</h1>
                            </div>
                        })}

                    </div>}

                </div>
            </div>

        </div >



    </>






}