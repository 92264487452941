// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.nav-links li p  {
    color: #758694 !important;
    text-decoration: none;
}

.nav-links li p:hover {
        color: #405d72 !important;
}`, "",{"version":3,"sources":["webpack://./src/Components/NavBar/NavBar.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,qBAAqB;AACzB;;AAEA;QACQ,yBAAyB;AACjC","sourcesContent":["\n.nav-links li p  {\n    color: #758694 !important;\n    text-decoration: none;\n}\n\n.nav-links li p:hover {\n        color: #405d72 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
