import React, { useState, useEffect } from 'react';
import axios from 'axios';
import img from './Auth.svg';
import logo from '../../images/logo.svg'
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';

export default function Login({ baseUrl }) {
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const Error = (message) => {
        api.error({
            type: 'error',
            message: `${message}`,
            placement: 'topRight',
        });
    };


    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${baseUrl}/api/User/Login`, {
                email,
                password,
            });

            if (response.status === 200) {
                
                if (response.data.role === 0) {
                    localStorage.setItem('superToken', response.data.token)
                    navigate('/superAdmin/admins')
                }
                else if (response.data.role === 1) {
                    localStorage.setItem('adminToken', response.data.token)
                    navigate('/admin/providers')
                }
                else if (response.data.role === 2) {
                    localStorage.setItem('providerToken', response.data.token)
                    localStorage.setItem('providerID', response.data.id)
                    navigate('/provider/home')
                }
                else if (response.data.role === 3) {
                    localStorage.setItem('userToken', response.data.token)
                    navigate('/home')
                }

                // Redirect or perform other actions on successful login
            }
        } catch (error) {
            if (error.response && error.response.status === 400) {
                Error(error?.response?.data.errors[0] ? error?.response?.data.errors[0] : 'Email already in use or invalid data');
            } else {
                Error('Login failed');
            }
        }
    };

    return (
        <div className='px-4 sm:px-10 w-screen h-screen flex flex-col sm:flex-row justify-center sm:justify-between items-center relative'>
            {contextHolder}
            <div className='flex flex-col w-full sm:w-1/2 gap-10 sm:relative absolute bottom-0 top-0 justify-center z-10 backdrop-blur-sm sm:p-0 px-5'>
                <div className='container mx-auto'>
                    <img src={logo} alt="Logo" className='cursor-pointer' onClick={() => { navigate('/') }} />
                </div>
                <form onSubmit={handleLogin} className='flex flex-col gap-4 sm:gap-10  '>
                    <input
                        type="text"
                        placeholder='Email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className='bg-white w-full px-4 py-3 sm:py-5 rounded-full text-black text-center'
                    />
                    <input
                        type="password"
                        placeholder='Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='bg-white w-full px-4 py-3 sm:py-5 rounded-full text-black text-center'
                    />

                    <div className='text-center'>
                        <button type="submit" className='w-full sm:w-auto text-2xl sm:text-5xl text-[--yellow] hover:text-yellow-400 anton'>
                            Sign in
                        </button>
                        <p className="mt-5 text-center text-sm">
                            No account?
                            <p
                                className="ms-2 font-semibold leading-6 text-[--yellow] hover:text-yellow-400 cursor-pointer"
                                onClick={() => { navigate('/sign-up') }}
                            >
                                Create one
                            </p>
                        </p>
                    </div>
                </form>
            </div>
            <img src={img} alt="Auth illustration" className=' sm:block h-full sm:w-1/2 sm:scale-100 scale-150 sm:opacity-100 opacity-20 object-contain' />
        </div >
    );
}
