import React, { useEffect, useState } from 'react'
import Navbar from '../../../Components/NavBar/NavBar'
import { Outlet } from 'react-router-dom'
import Footer from '../../../Components/Footer/Footer'
import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen';

export default function Layout({baseUrl}) {
    const [loading, setloading] = useState(true);

    useEffect(() => {
        setloading(true)
        setTimeout(() => {
            setloading(false)
        }, 4000);
    }, []);
    return <>
        {loading ? <LoadingScreen /> : null}

        <Navbar baseUrl={baseUrl} />
        <div className='min-h- '>
        <Outlet />
        </div>
        <Footer/>
    </>
}
