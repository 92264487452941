import React, { useEffect, useState } from 'react'
import './Home.css'
import logo from './../../../images/darklogo.svg'
import logo2 from '../../../images/logo.svg'
import { Avatar, Card } from 'antd'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { motion } from "framer-motion";
import TestimonialCard from '../../../Components/TestimonialCard/TestimonialCard.jsx'

export default function Home({ baseUrl }) {
  const [categoryData, setCategoryData] = useState([])

  async function GetCategoryList() {
    await axios.get(`${baseUrl}/api/Category/GetCategoryList`).then((res) => {
      setCategoryData(res.data)
      

    }).catch((err) => {
      
    })
  }
  useEffect(() => {

    GetCategoryList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();
  return <>
    <section className='banner  bg-center md:bg-top sm:h-[95vh] h-[60vh]'>
      <video autoPlay loop muted className=' h-96 ' >
        <source src={require('./home.mp4')} type="video/mp4" />
        {/* <source src={'https://bosla-courses.s3.eu-north-1.amazonaws.com/Download+(4).mp4'} type="video/mp4" /> */}
        Your browser does not support the video tag.
      </video>

      <div className='container  sm:space-y-10 space-y-5 flex flex-col justify-center h-full  '>
        <motion.img
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 60 }}
          viewport={{ once: true }} // This ensures the animation only runs once
          src={logo}
          alt=""
          className="md:w-1/2 w-96 drop-shadow-lg sm:mx-0 mx-auto sm:inline"
        />
        <h1 className='text-[--main-color]  sm:ms-20 font-bold sm:text-6xl text-4xl sm:text-start text-center'>Explore-Learn-Thrive</h1>
        <p className='text-[--yellow]  sm:ms-20 font-bold sm:text-4xl text-2xl   sm:text-start text-center '>Your adventure awaits. <br /> We prepare you to conquer it. </p>
        <button class="flex items-center sm:text-3xl text-2xl  sm:ms-20 mx-auto bg-gray-800 bg-opacity-70 text-white font-bold py-5 px-5 rounded-full shadow-lg hover:bg-opacity-80" onClick={() => {navigate('/categories')  }}>
          START YOUR JOURNEY
          <i class="fa fa-chevron-circle-right ml-2"></i>
        </button>
      </div>
    </section>


    <section className="py-10 sm:px-5" id='our-courses'>
      <motion.h1 initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 60 }}
        viewport={{ once: true }}
        className=" mt-16 font-bold md:text-5xl text-3xl text-center">In which area do you want to develop?</motion.h1>
      <motion.div initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 60 }} 
        viewport={{ once: true }}
        className="grid md:grid-cols-3 grid-cols-2 justify-around w-full 2xl:gap-44 gap-12 xl:px-28 2xl:px-56 px-5 pt-7">
        {categoryData?.map((item, index) => {
          return <Link to={`/Courses/${item?.id}`} className=" flex text-white no-underline flex-col justify-center items-center  sm:mb-0 hover:text-[--yellow]">
            <img src={`${baseUrl}/api/Storage/GetImageById?id=${item?.image}`} alt="" className="w-full sm:w-auto drop-shadow-lg transition-all duration-300 cursor-pointer hover:scale-105 " />
            <h1 className="mt-4 sm:mt-16 font-bold text-2xl sm:text-5xl text-center  transition-all duration-300">{item?.name}</h1>
          </Link>
        })}
      </motion.div>
    </section>

    <div className='border-b-2 border-[--yellow] container mx-auto my-14 sm:my-20' id='who-we-are' />

    <section className=' py-10  flex justify-center  sm:px-0 px-5' >
      <div className='container flex-row '>
        <motion.div initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 60 }}
          viewport={{ once: true }} className=' flex flex-col-reverse justify-between items-center lg:flex-row lg:gap-0 '>
          <h1 className='   font-bold md:text-6xl text-4xl text-center'>Who We Are?</h1>
          <img src={logo2} className='lg:block hidden' alt="" />
        </motion.div>

        <motion.div initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 60 }}
          viewport={{ once: true }} className='my-10'>
          <h1 className='font-bold md:text-6xl text-4xl'>Our vision?</h1>
          <h3 className=' md:text-4xl text-2xl '>Your adventure awaits. We prepare you to conquer it. </h3>
        </motion.div>

        <motion.div initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 60 }}
          viewport={{ once: true }} >
          <h1 className='font-bold md:text-6xl text-4xl'>Our Mission?</h1>
          <h3 className=' md:text-4xl text-2xl '>Our mission is to provide you with various programs and courses that can help you improve your skills in order to be able to thrive in life.</h3>
        </motion.div>
      </div>
    </section>

    <div className='border-b-2 border-[--yellow] container mx-auto  my-14 sm:my-20' id='testimonials' />

    <section className=' py-10 px-10' >
      <TestimonialCard />
    </section>




  </>
}
