import React from 'react'
import ProvdierNav from '../../../Components/NavBar/ProviderNav'
import { Outlet } from 'react-router-dom'

export default function ProviderLayout({baseUrl}) {
  return <>
    <ProvdierNav baseUrl={baseUrl} />
    <Outlet />
  </>
}
