import React, { useEffect, useState } from 'react'
import CourseCard from '../../../Components/Cards/CourseCard.jsx'
import EmptyPage from '../../../Components/EmptyPage/EmptyPage.jsx'
import { Input } from 'antd';
import { Slider } from 'antd';
import { Select } from 'antd';
import axios from 'axios';
import { Option } from 'antd/es/mentions/index.js';


export default function ExploreCourses({ baseUrl }) {

    const [categoryData, setCategoryData] = useState([])
    const [providersData, setProvidersData] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [providerId, setProviderId] = useState('')
    const [nameSearch, setNameSearch] = useState('')
    const [courses, setCourses] = useState([])
    const [price, setPrice] = useState([0, 15000])

    const [open, setOpen] = useState(false)
    const [err, setErr] = useState(false)




    const headers = {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    }

    async function GetProviders() {
        axios.get(`${baseUrl}/api/Provider/providers`).then((res) => {
            setProvidersData(res.data)

        }).catch((err) => {

        })
    }

    async function GetCategoryList() {
        await axios.get(`${baseUrl}/api/Category/GetCategoryList`).then((res) => {
            setCategoryData(res.data)
        }).catch((err) => {

        })
    }

    async function GetCourseList() {
        await axios.get(`${baseUrl}/api/Course/GetCoursesList?Name=${nameSearch}&categoryId=${categoryId}&providerId=${providerId}&minPrice=${price[0]}&maxPrice=${price[1]}`, localStorage.getItem('userToken') ? { headers } : '').then((res) => {

            setCourses(res.data.courses)
            

            setErr(false)
        }).catch((err) => {
            setErr(true)


        })
    }



    const onChangeCategory = (value) => {
        if (value !== undefined) {

            setCategoryId(value)
        } else {
            setCategoryId('')
        }

    };
    const onChangeProvider = (value) => {
        if (value !== undefined) {

            setProviderId(value)
        } else {
            setProviderId('')
        }

    };
    const onSearch = (e) => {
        if (e.target.value === '') {

            setNameSearch(e.target.value)
        } else {
            setNameSearch('')
        }

    };

    const onPrice = (value) => {
        setPrice(value)


    };


    const handleClick = () => {
        setOpen(prev => !prev)
    }
    useEffect(() => {
        GetCourseList()
        GetCategoryList()
        GetProviders()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameSearch, categoryId, providerId, price])
    return (
        <>
            <div className='container mx-auto   mt-10'>

                <div className='flex lg:flex-row flex-col gap-3 '>

                    <div
                        className={`md:w-1/4 bg-[--bg-secoundry-color] px-3 h-fit py-5 pt-2 rounded-md transition-all duration-500 overflow-hidden ${open ? 'max-h-96' : 'max-h-12'}`}
                    >
                        <div className="flex justify-between items-center mb-3 cursor-pointer" onClick={handleClick}
>
                            <span className="flex items-center w-full">
                                <i className="fa fa-filter me-2"></i>
                                <h1>Filter</h1>
                            </span>
                            <i className={`${open ? '' : 'rotate-180'} fa fa-angle-up transition-all duration-500`}></i>
                        </div>

                        <Input
                            prefix={<i className="fa fa-search text-gray-400 me-2" />}
                            type="search"
                            className="w-full p-1 rounded-md text-black"
                            placeholder="Search"
                            name="search"
                            id="search"
                            onChange={onSearch}
                        />

                        <h2 className="my-1">Category :</h2>
                        <Select
                            allowClear
                            className="w-full"
                            showSearch
                            placeholder="Select a Category"
                            optionFilterProp="label"
                            onChange={onChangeCategory}
                            onSearch={onSearch}
                        >
                            {categoryData.map((category) => (
                                <Option key={category.id} value={category.id}>
                                    {category.name}
                                </Option>
                            ))}
                        </Select>

                        <h2 className="my-1">Provider :</h2>
                        <Select
                            allowClear
                            showSearch
                            placeholder="Select a Provider"
                            optionFilterProp="label"
                            className="w-full"
                            onChange={onChangeProvider}
                            onSearch={onSearch}
                        >
                            {providersData.map((provider) => (
                                <Option key={provider.id} value={provider.id}>
                                    {provider.userName}
                                </Option>
                            ))}
                        </Select>

                        <h2>Price</h2>
                        <Slider
                            onChange={onPrice}
                            range={{ draggableTrack: true }}
                            defaultValue={[0, 15000]}
                            max={15000}
                            min={0}
                        />

                        <div className="flex gap-5 justify-center">
                            <div>
                                <p className="text-sm font-bold text-white rounded-md p-1">
                                    {price[0]} EGP
                                </p>
                            </div>
                            <div>
                                <p className="text-sm font-bold text-white rounded-md p-1">
                                    {price[1]} EGP
                                </p>
                            </div>
                        </div>
                    </div>


                    <div className={`grid grid-cols-2  md:grid-cols-3  mx-2 md:w-3/4
                        transition-all duration-1000
                        col-span-12  gap-4   ${open === true ? 'md:col-span-12 ' : 'md:col-span-10'} `}>
                        {courses?.length > 0 && err === false && courses?.map((course) => {
                            return <CourseCard course={course} baseUrl={baseUrl} />

                        })}


                        {err === true ? <EmptyPage /> : ''}


                    </div>
                </div>

            </div >
        </>
    )
}





