import React, { useEffect, useState } from 'react'
import './profile.css'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { Image } from 'antd';
import { motion } from 'framer-motion';



export default function ProviderProfile({ baseUrl, show }) {
  const [profileData, setprofileData] = useState();
  const [providerCourses, setProviderCourses] = useState([]);
  const providerToken = localStorage.getItem('providerToken')
  const navigate = useNavigate()

  const { id } = useParams()



  const headers = {
    Authorization: `Bearer ${providerToken}`,
  }
  useEffect(() => {
    getProfileData()
    GetCourseList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProfileData = async () => {
    await axios.get(`${baseUrl}${show ? `/api/Provider/getProvider?providerId=${id}` : '/api/Provider/GetMyProfile'}`, { headers })
      .then((res) => {
        setprofileData(res.data)
        


      }).catch((err) => {
        

      });
  };

  async function GetCourseList() {
    await axios.get(`${baseUrl}/api/Course/GetCoursesList?providerId=${id ? id : localStorage.getItem('providerID')}`).then((res) => {
      setProviderCourses(res.data.courses)

    }).catch((err) => {

    })
  }

  return (
    <div className="h-full  bg-[--main-color]   container mx-auto space-y-5 ">
      <div className=" flex  relative w-full mt-5 ">
        <img
          src={`${baseUrl}/api/Storage/GetImageById?id=${profileData?.coverId}`}
          alt='Profile' className="w-full absolute h-40  bg-gray-400 object-cover " />

        <div className='md:col-span-3 col-span-12   flex md:justify-end justify-center bg--500'>
          <div className='size-36 overflow-hidden rounded-full ms-2 mt-16'>
            <Image

              src={profileData?.imageId === undefined || profileData?.imageId === null ? 'https://th.bing.com/th/id/OIP.3DtAyOwRrErzTWGNKjf3RwHaHa?rs=1&pid=ImgDetMain' : `${baseUrl}/api/Storage/GetImageById?id=${profileData?.imageId}`}

              alt='Profile' className="w-full border-4 border-white rounded-full object-cover" />
          </div>
        </div>


      </div>
      
     <div  className='md:col-span-4 col-span-12 flex flex-col justify-center md:items-start items-center space-y-1  '>
        <p className='text-4xl'>{profileData?.title || profileData?.title !== '' ? profileData?.title : profileData?.username}</p>
        <a
          href={`mailto:${profileData?.email}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            if (!profileData?.email) {
              e.preventDefault();
              alert('Email address is not available');
            }
          }}
          className="text-white hover:text-gray-200 text-decoration-none  "
        >
          {profileData?.email}
        </a>

      </div >

        <div initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 60 }} 
        viewport={{ once: true }} className='flex md:justify-start justify-center mt-5   w-full '>
          {profileData?.facebookLink === null ? '' :
            <a class="socialContainer containerTwo"
              target="_blank"
              href={`${profileData?.facebookLink}`}
              rel="noopener noreferrer"
            >

              <svg
                viewBox="0 0 320 512"
                height="1.2em"
                fill="currentColor"
                class="socialSvg twitterSvg"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </a>


          }

          {profileData?.linkedInLink === null ? '' :
            <a class="socialContainer containerThree" 
            href={`${profileData?.linkedInLink}`}
              target="_blank"
              rel="noopener noreferrer"
            >
    <svg viewBox="0 0 448 512" class="socialSvg linkdinSvg">
      <path
        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
      ></path>
    </svg>
  </a>
         

          }

          {profileData?.personalWebsite === null ? '' :
            <a class="socialContainer containerFour"
              href={`${profileData?.personalWebsite}`}
              target="_blank"
              rel="noopener noreferrer"
            >

              <svg
                class="socialSvg whatsappSvg"

                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M352 256c0 22.2-1.2 43.6-3.3 64l-185.3 0c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64l185.3 0c2.2 20.4 3.3 41.8 3.3 64zm28.8-64l123.1 0c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64l-123.1 0c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32l-116.7 0c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0l-176.6 0c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0L18.6 160C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192l123.1 0c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64L8.1 320C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6l176.6 0c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352l116.7 0zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6l116.7 0z"
                ></path>
              </svg>
            </a>


          }

        </div>

      <motion.div initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 60 }} 
        viewport={{ once: true }} className='text-start my-10 '>
        <p className='md:text-[35px] text-[30px] '>Know More About {profileData?.title || profileData?.title !== '' ? profileData?.title : profileData?.username}</p>
        <motion.p initial={{ y: 50, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 60 }}
          viewport={{ once: true }} className='md:text-lg text-[25px] text-[--yellow] '>{profileData?.aboutMe} </motion.p>
      </motion.div>

      <div className="flex-1     rounded-lg   mt-4">
        <h4 className="text-3xl t text-white-900 ">Courses</h4>
        <div class=" w-full flex justify-center items-center py-10">
          <motion.div initial={{ y: 50, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 60 }}
            viewport={{ once: true }}  class="md:px-4 grid md:grid-cols-3 lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5  md:space-y-0">

            {providerCourses.map((course) => {
              return <div className='cursor-pointer hover:scale-105 duration-300 transition-all' onClick={() => { navigate(`/Course/${course.id}`) }}>
                <img src={`${baseUrl}/api/Storage/GetImageById?id=${course.cover}`} className="w-full h-60 rounded-lg" alt="" />
                <h1 className='text-center text-[--yellow] text-[25px]  mt-2'>{course.name}</h1>
              </div>
            })}

          </motion.div>
        </div>
      </div>


    </div>
  )
}
