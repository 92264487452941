import React, { useEffect, useState } from 'react'
import CourseCard from '../../../Components/Cards/CourseCard.jsx'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import EmptyPage from '../../../Components/EmptyPage/EmptyPage.jsx'
import LoadingScreen from '../../../Components/LoadingScreen/LoadingScreen.jsx'

export default function CategoriesCoursesDetails({ baseUrl }) {
    const [categoryCourses, setCategoryCourses] = useState([])
    const [categoryData, setCategoryData] = useState('')
    const [loading, setLoading] = useState(false)
    const headers = {
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    }
    const token = localStorage.getItem('userToken')

    const { id } = useParams()

    async function getCategoryCourses() {
        // setLoading(true)
        await axios.get(`${baseUrl}/api/Course/GetCategoryCourses?CategoryId=${id}`,
            token ? { headers } : {}).then((res) => {

                setCategoryCourses(res.data.courses)
                setCategoryData(res.data)
                setLoading(false)


            }).catch((err) => {
                

            })
    }
    useEffect(() => {
        getCategoryCourses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <>
            {loading === true ? <LoadingScreen /> :
                <div className='min-h-screen py-28  container mx-auto'>
                    {/* <div className=' h-72 relative bg-black bg-opacity-25  flex justify-center items-center' >
                        <h3 className='text-3xl font-bold'>{categoryData.categoryName} </h3>
                        <img src={`${baseUrl}/api/Storage/GetImageById?id=${categoryData?.categoryImage}`} className='h-full -z-10 absolute  w-full object-cover rounded-md ' alt="Category Image" />
                    </div> */}
                    {/* <img src={ico} alt="" className='w-20  ms-auto'/> */}
                    <h1 className='text-5xl text-center  font-bold mb-10'>{categoryData.categoryName} Courses</h1>

                    <div className='grid grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 container mx-auto mt-5 lg:gap-20 items-center md:gap-10 gap-2 px-1  md:px-0'>
                        {categoryCourses.length > 0 && categoryCourses.map((course) => {
                            return <CourseCard course={course} baseUrl={baseUrl} /> 
                           

                        })}
                        {categoryCourses.length === 0 && (
                            <div className='w-full h-100  col-span-12 flex justify-center items-center'>
                                <h1 className='text-2xl'><EmptyPage mssg={"Coming Soon"}/> </h1>
                            </div>
                        )}
                    </div>
                </div>
            }

        </>
    )
}
