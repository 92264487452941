import React, { useEffect, useState } from 'react';
import img1 from '../../../images/logo.svg';
import creditCard from '../../../images/credit-card.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {  message,  } from 'antd';
import './payment.css';
import PaymentSteps from '../../../Components/PaymentSteps/PaymentSteps';

export default function Payment({ baseUrl }) {
    let { id } = useParams();
    let navigate = useNavigate();
  

    const [courseDetails, setCourseDetails] = useState('');
 
    const getCourseDetails = async () => {
        await axios.get(`${baseUrl}/api/Course/GetCourseById?Id=${id}`).then((res) => {
            setCourseDetails(res.data);
        }).catch((err) => {
            message.error("Failed to fetch course details");
        });
    };

    useEffect(() => {
        getCourseDetails();
    }, []);

    return (
        <div className='container mx-auto md:my-10 md:p-10 my-2 p-2'>
            <div className='grid grid-cols-1'>
                <div className='flex justify-between items-center'>
                    <p className='md:text-[40px] text-[30px] mx-2'>Pay Now</p>
                    <img src={img1} onClick={() => navigate('/home')} className='md:w-44 w-40 cursor-pointer' alt="" />
                </div>
            </div>
            <div className='grid grid-cols-1 my-2'>
                <p className='text-[46px] text-[--yellow] text-center'>{courseDetails.name}</p>
            </div>

            <div className='grid grid-cols-1 mt-5'>
                <p className='bg-gray-700 text-white placeholder:text-gray-400 p-4 rounded-full md:w-96 w-full text-center'>
                    Total Payment = {courseDetails.price} EGP
                </p>
            </div>

            <div className='grid md:grid-cols-12 grid-cols-2 mt-5 w-full'>
                <div className='col-span-4 flex justify-center items-center'>
                    <img src={creditCard} className='md:w-64 w-0' alt="" />
                </div>
                <div className='w-full md:col-span-8 col-span-12 flex flex-col items-center justify-center'>
                    <PaymentSteps baseUrl={baseUrl}/>
                </div>
            </div>
       


        </div>
    )
}
