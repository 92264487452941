// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-1fxpy2m).ant-collapse>.ant-collapse-item:last-child,
:where(.css-dev-only-do-not-override-1fxpy2m).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{
    border-radius: 0px 0px 0px 0px !important;
}
/* .video-react-control-bar{
    position: relative !important;
} */`, "",{"version":3,"sources":["webpack://./src/Pages/User/WatchCourse/WatchCourse.css"],"names":[],"mappings":"AAAA;;IAEI,yCAAyC;AAC7C;AACA;;GAEG","sourcesContent":[":where(.css-dev-only-do-not-override-1fxpy2m).ant-collapse>.ant-collapse-item:last-child,\n:where(.css-dev-only-do-not-override-1fxpy2m).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{\n    border-radius: 0px 0px 0px 0px !important;\n}\n/* .video-react-control-bar{\n    position: relative !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
