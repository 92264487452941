import React from 'react'
import TestimonialCard from '../../../Components/TestimonialCard/TestimonialCard.jsx'

export default function Testimonials() {
  return (
    <>
      <div className='container mx-auto my-5'>

        <TestimonialCard />
      </div>
    </>
  )
}
