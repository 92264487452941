// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner {
    width: 100%;
    position: relative;
    background-position: top top;
    overflow: hidden;
    
}
.banner video{
    position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);
        z-index: -1;
        filter:grayscale(0.1)
        /* Ensures video stays in the background */
    }

`, "",{"version":3,"sources":["webpack://./src/Pages/User/Home/Home.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,4BAA4B;IAC5B,gBAAgB;;AAEpB;AACA;IACI,kBAAkB;QACd,QAAQ;QACR,SAAS;QACT,WAAW;QACX,YAAY;QACZ,iBAAiB;QACjB,gCAAgC;QAChC,WAAW;QACX,oBAAoB;QACpB,0CAA0C;IAC9C","sourcesContent":[".banner {\n    width: 100%;\n    position: relative;\n    background-position: top top;\n    overflow: hidden;\n    \n}\n.banner video{\n    position: absolute;\n        top: 50%;\n        left: 50%;\n        width: 100%;\n        height: 100%;\n        object-fit: cover;\n        transform: translate(-50%, -50%);\n        z-index: -1;\n        filter:grayscale(0.1)\n        /* Ensures video stays in the background */\n    }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
